import { ILoginUser, ICheckUserEmail, ICreateUser } from 'models/user'
import { IErrorMessage } from 'models/error'

export enum ACTION_TYPE {
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE',
  CHECK_EMAIL = 'CHECK_EMAIL',
  CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE = 'CHECK_EMAIL_FAILURE',
  CHANGE_STEP = 'CHANGE_STEP',
  SET_LANGUAGE = 'SET_LANGUAGE',
  RESET_ERROR = 'RESET_ERROR',
  USER_REGISTER = 'USER_REGISTER',
  USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
}

export interface ILoginAction {
  type: typeof ACTION_TYPE.USER_LOGIN
  payload: ILoginUser
}

export interface ILoginSuccessAction {
  type: typeof ACTION_TYPE.USER_LOGIN_SUCCESS
}

export interface ILoginFailureAction {
  type: typeof ACTION_TYPE.USER_LOGIN_FAILURE
  payload: {
    error: string
  }
}

export interface ICheckEmailAction {
  type: typeof ACTION_TYPE.CHECK_EMAIL
  payload: ICheckUserEmail
}

export interface ICheckEmailSuccessAction {
  type: typeof ACTION_TYPE.CHECK_EMAIL_SUCCESS
}

export interface ICheckEmailFailureAction {
  type: typeof ACTION_TYPE.CHECK_EMAIL_FAILURE
  payload: {
    error: string
  }
}

export interface IChangeStepAction {
  type: typeof ACTION_TYPE.CHANGE_STEP
  payload: {
    step: number
  }
}

export interface ISetLanguageAction {
  type: typeof ACTION_TYPE.SET_LANGUAGE
  payload: {
    language: string
  }
}

export interface IActivateAccountAction {
  type: typeof ACTION_TYPE.ACTIVATE_ACCOUNT
  payload: {
    ac: string
    password: string
    isMobileApp: boolean
  }
}

export interface IResetEmailAction {
  type: typeof ACTION_TYPE.RESET_ERROR
}

export interface IRegisterUserAction {
  type: typeof ACTION_TYPE.USER_REGISTER
  payload: ICreateUser & IErrorMessage & { isMobileApp: boolean }
}

export interface IRegisterUserSuccessAction {
  type: typeof ACTION_TYPE.USER_REGISTER_SUCCESS
}

export interface IRegisterUserFailureAction {
  type: typeof ACTION_TYPE.USER_REGISTER_FAILURE
  payload: {
    error: string
  }
}

export type IActionType =
  | ILoginAction
  | ILoginSuccessAction
  | ILoginFailureAction
  | ICheckEmailAction
  | ICheckEmailSuccessAction
  | ICheckEmailFailureAction
  | IChangeStepAction
  | ISetLanguageAction
  | IResetEmailAction
  | IRegisterUserAction
  | IRegisterUserSuccessAction
  | IRegisterUserFailureAction
  | IActivateAccountAction
