import { IncomingMessage, ServerResponse } from 'http'

class RequestContext {
  req?: IncomingMessage
  /**
   * `HTTP` response object.
   */
  res?: ServerResponse

  store: any

  set(store: any, req?: IncomingMessage, res?: ServerResponse) {
    this.store = store
    this.req = req
    this.res = res
  }
}

export default new RequestContext()
