import * as Sentry from '@sentry/node'
import { Transaction, SpanContext } from '@sentry/types'
import SessionProvider from 'session'
import { getRegion } from './getRegion'

type Context =
  | Pick<SpanContext, Exclude<keyof SpanContext, 'spanId' | 'sampled' | 'traceId' | 'parentSpanId'>>
  | undefined

class SentryTransaction {
  private transaction: Transaction | undefined
  private name: string
  private context: Context

  constructor(name: string, context?: Context) {
    this.name = name
    this.context = context
  }

  public start = (): void => {
    this.transaction = Sentry.startTransaction({
      name: this.name,
      op: 'http',
    })
    this.transaction.setTag('service', this.context?.data?.service)
    this.transaction.setTag('endpoint', this.context?.data?.url?.split('?')[0])
    this.transaction.setTag('whichend', process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front')
    this.transaction.setTag('region', getRegion())
    const entryPoint = SessionProvider.getEntryPoint()
    entryPoint && this.transaction.setTag('entryPoint', entryPoint)
  }

  public finish = (httpStatus = 200): void => {
    // finish only the unfinished transaction
    if (!this.transaction?.endTimestamp) {
      this.transaction?.setHttpStatus(httpStatus)
      this.transaction?.finish()
    }
  }

  /**
   * Starts a transaction that trace an API call.
   * @param name Human-readable identifier for the transaction.
   * @param context Any kind of data related to the given API call.
   * @returns a function that can ends the current transaction (if name is provided), otherwise empty function.
   */
  static beginTransaction = (name?: string, context?: Context): ((httpStatus?: number) => void) => {
    if (!name) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    }

    const transaction = new SentryTransaction(name, context)

    transaction.start()

    return transaction.finish
  }
}

export default SentryTransaction
