import {
  ACTION_TYPE,
  ISaveContactDetailsAction,
  ISaveContactDetailsFailureAction,
  ISaveContactDetailsSuccessAction,
  IUpdatePreferencesAction,
  IUpdatePreferencesFailureAction,
  IUpdatePreferencesSuccessAction,
} from 'actions/accountCreation/types'
import { IThirdStepValues } from 'models/forms'
import { ContactDetailsApiResponse, ContactDetailsPayload } from 'services/contact-details'

export const createSaveContactDetailsAction = (
  data: ContactDetailsPayload,
  errorMessage: string
): ISaveContactDetailsAction => ({
  type: ACTION_TYPE.SAVE_CONTACT_DETAILS,
  payload: { ...data, errorMessage },
})

export const createSaveContactDetailsSuccessAction = (
  payload: ContactDetailsApiResponse
): ISaveContactDetailsSuccessAction => ({
  type: ACTION_TYPE.SAVE_CONTACT_DETAILS_SUCCESS,
  payload,
})

export const createSaveContactDetailsFailureAction = (
  error: string
): ISaveContactDetailsFailureAction => ({
  type: ACTION_TYPE.SAVE_CONTACT_DETAILS_FAILURE,
  payload: { error },
})

const updatePreferencesAction = (
  data: IThirdStepValues,
  error: string
): IUpdatePreferencesAction => ({
  type: ACTION_TYPE.UPDATE_PREFERENCES,
  payload: { data, error_message: error },
})

const updatePreferencesSuccessAction = (): IUpdatePreferencesSuccessAction => ({
  type: ACTION_TYPE.UPDATE_PREFERENCES_SUCCESS,
})

const updatePreferencesFailureAction = (error: string): IUpdatePreferencesFailureAction => ({
  type: ACTION_TYPE.UPDATE_PREFERENCES_FAILURE,
  payload: { error },
})

export default {
  updatePreferencesAction,
  updatePreferencesSuccessAction,
  updatePreferencesFailureAction,
  createSaveContactDetailsAction,
  createSaveContactDetailsSuccessAction,
  createSaveContactDetailsFailureAction,
}
