import { AccountPayload, ACTION_TYPE, createAccountAction } from 'actions/account'
import { Action } from 'redux'
import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects'
import accountService, { AccountApiResponse } from 'services/account'

export const sagas = [takeEvery(ACTION_TYPE.GET_ACCOUNT_DETAILS, getAccountDetails)]

export function* getAccountDetails(): Generator<
  | AccountApiResponse
  | PutEffect<Action<ACTION_TYPE>>
  | CallEffect<AccountApiResponse>
  | CallEffect<AccountPayload>
  | unknown
> {
  try {
    const result: AccountApiResponse = (yield call(
      accountService.getAccount
    ) as unknown) as AccountApiResponse

    yield put(createAccountAction(ACTION_TYPE.GET_ACCOUNT_DETAILS_SUCCESS, result))
  } catch (error) {
    yield put(
      createAccountAction(ACTION_TYPE.GET_ACCOUNT_DETAILS_FAILURE, {
        error: 'Could not load account',
      })
    )
  }
}
