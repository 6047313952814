const MaximalFooter = () => (
  <div className="chr-header-footer">
    {/* 
// @ts-ignore */}
    <chr-footer data-namespace="footer"></chr-footer>
    {/* 
// @ts-ignore */}
    <chr-modal-provider></chr-modal-provider>
  </div>
)

const MinimalFooter = () => null

export enum FooterSize {
  Minimal,
  Maximal,
}

export const Footer = ({ size }: { size: FooterSize }): JSX.Element =>
  size === FooterSize.Maximal ? <MaximalFooter /> : <MinimalFooter />
