import environment from 'configuration/env'

export const ROUTE = {
  HOME: '/',
  BENEFITS: '/account-benefits',
  SIGN_UP: '/signup',
  WELCOME: '/welcome',
  CONFIRM_DETAILS: '/confirm-details',
  KYC_PERSONAL_DETAILS: '/address',
  ID_VERIFICATION: '/id-verification',
  SECURITY_QUESTIONS: '/security-questions',
  COMPLETE: '/complete',

  CHRISTIES: environment.dotComUrl,
  CLIENT_SERVICES: `${environment.dotComUrl}/about-us/help`,
  PRIVACY_NOTICE: `${environment.dotComUrl}/about-us/contact/privacy`,
}

export type Links = {
  url: string
  tKey: string
}[]

export const navigationLinks: Links = [
  {
    url: ROUTE.HOME,
    tKey: 'tabs.sign_in',
  },
  {
    url: ROUTE.BENEFITS,
    tKey: 'tabs.create_account',
  },
]
