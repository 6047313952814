import { Action } from 'redux'
import { DOCUMENT_TYPE } from 'configuration'

export enum ACTION_TYPE {
  KYC_DOCUMENT_UPLOAD = 'KYC_DOCUMENT_UPLOAD',
  KYC_DOCUMENT_UPLOAD_SUCCESS = 'KYC_DOCUMENT_UPLOAD_SUCCESS',
  KYC_DOCUMENT_UPLOAD_FAILURE = 'KYC_DOCUMENT_UPLOAD_FAILURE',
}

type KycDocumentPayload = {
  payload: {
    file: File
    documentType: DOCUMENT_TYPE
  }
}

export type KycDocumentUpload = Action<ACTION_TYPE.KYC_DOCUMENT_UPLOAD> & KycDocumentPayload

export type KycDocumentUploadSuccess = Action<ACTION_TYPE.KYC_DOCUMENT_UPLOAD_SUCCESS>

export type KycDocumentUploadFailure = Action<ACTION_TYPE.KYC_DOCUMENT_UPLOAD_FAILURE>

export type IActionType = KycDocumentUpload | KycDocumentUploadSuccess | KycDocumentUploadFailure
