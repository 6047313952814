import { env } from 'next-runtime-env'

export enum Regions {
  CN = 'CN',
  EN = 'EN',
}

export const getRegion = (): string =>
  (env('NEXT_PUBLIC_REGION_CONFIG') as string) === Regions.EN ? Regions.EN : Regions.CN

export const isChineseRegion = (): boolean => {
  const env = getRegion()
  return env === Regions.CN
}
