export const ANALYTICS_CONFIG = {
  localStorageNamespace: 'CHR_LOGGING',
  regionAttribute: 'data-analytics-region',
  channel: 'Account',
}

export const CUSTOM_FORM_EVENTS = {
  LIGHT_START: 'reg_load',
  LIGHT_SUCCESS: 'light_registration',
  FULL_START: 'reg_convert_load',
  FULL_SUCCESS: 'full_registration',
  LOGIN_SUCCESS: 'login',
}

export interface pageType {
  page: string
  step?: string
  onStart?: string
  onSuccess?: string
}

export interface configType {
  [key: string]: pageType
}

export const PAGE_CONFIG: configType = {
  SIGN_IN: {
    page: 'account:create account landing',
    step: 'account:sign in',
    onSuccess: CUSTOM_FORM_EVENTS.LOGIN_SUCCESS,
  },
  CREATE_ACCOUNT_START: {
    page: 'account:account benefits',
  },
  CREATE_ACCOUNT_1: {
    page: 'account:create account | email + pass',
    step: 'account:create-light-step-1',
    onStart: CUSTOM_FORM_EVENTS.LIGHT_START,
  },
  CREATE_ACCOUNT_2: {
    page: 'account:create account | personal detail',
    step: 'account:create-light-step-2',
    onSuccess: CUSTOM_FORM_EVENTS.LIGHT_SUCCESS,
  },
  CREATE_ACCOUNT_3: {
    page: 'account:create account | comms preference',
    step: 'account:create-light-step-3',
  },
  WELCOME: {
    page: 'account:welcome',
    step: 'account:create-light-step-4',
  },

  CONIRM_DETAILS: {
    page: 'account:confirm details',
    step: 'account:create-full-step-1',
    onStart: CUSTOM_FORM_EVENTS.FULL_START,
  },
  KYC_ADDRESS: {
    page: 'account:KYC | Address',
    step: 'account:create-full-step-2',
  },
  KYC_ID: {
    page: 'account:KYC | User ID',
    step: 'account:create-full-step-3',
  },
  KYC_ID_VALIDATE_ID: {
    page: 'account:KYC | User ID',
    step: 'account:create-full-step-3 | validate ID',
  },
  KYC_ID_VALIDATE_POA: {
    page: 'account:KYC | User POA',
    step: 'account:create-full-step-3 | validate POA',
  },
  KYC_SECURITY: {
    page: 'account:KYC | Security Questions',
    step: 'account:create-full-step-4',
    onSuccess: CUSTOM_FORM_EVENTS.FULL_SUCCESS,
  },
  ACCOUNT_COMPLETE: {
    page: 'account:account complete',
    step: 'account:create-full-step-5',
  },
  ERROR_404: {
    page: '404 not found',
  },
}
