import environment from 'configuration/env'
import {
  LANGUAGE_CONFIG,
  SupportedLanguages,
  isSupportedLanguage,
} from 'configuration/translations'

export const isChristiesUrl = (url: string): boolean =>
  /^http(s)?:\/\/(.*\.)*christies\.com/.test(url)

export const isThisApplication = (url: string): boolean => url.startsWith(environment.thisAppUrl)

export const goToMyAccount = (): void =>
  window.location.assign(
    `${environment.dotComUrl}/mychristies/activities.aspx?activitytype=wc&submenutapped=true`
  )

export const decodeBase64 = (b64Encoded: string): string => {
  try {
    return atob(b64Encoded) // browser
  } catch (err) {
    return Buffer.from(b64Encoded, 'base64').toString() // node
  }
}

/**
 * Stringifies the `params` object and appends all parameters to the given url.
 * @param url base url
 * @param params all parameters that need to be stringified as querystring
 * @param skipEmpty skip empty value paramaters
 * @param overwrite if true, existing parameters will be overwritten
 * @returns url with the parameters as querystring
 */
export const appendParamsToUrl = (
  url: string,
  params: Record<string, any>,
  skipEmpty = true,
  overwrite = false
): string => {
  try {
    const urlObj = new URL(url)

    for (const name of Object.keys(params)) {
      if (skipEmpty && params[name] === '') continue

      if (overwrite) {
        urlObj.searchParams.set(name, params[name])
      } else {
        urlObj.searchParams.append(name, params[name])
      }
    }

    return urlObj.toString()
  } catch (error) {
    return ''
  }
}

/**
 * Appends the language parameter to the given url.
 * @param url Base URL
 * @param language Language to append to the url
 * @returns URL with the language parameter as querystring
 */
export const appendLangParamToUrl = (url: string, language: string): string =>
  appendParamsToUrl(url, { [LANGUAGE_CONFIG.paramName]: language }, true, true)

/**
 * Active link util goes with Navigation component to check if Link is ACTIVE
 * @param currentPath - router.pathname
 * @param url - dynamic parameter
 */
export const isNavLinkActive = (currentPath: string, url: string): boolean => currentPath === url

/**
 * Returns the language chosen by the user either in the query string or in cookies. Try query param, try cookie, default en.
 * @param queryLang - `sc_lang` from query string
 * @param cookieLang - `CurrentLanguage` from cookies
 */
export function getUserLanguage<T extends SupportedLanguages>(queryLang: T, cookieLang: T): T {
  if (isSupportedLanguage(queryLang)) {
    return queryLang
  }

  if (isSupportedLanguage(cookieLang)) {
    return cookieLang
  }

  return LANGUAGE_CONFIG.default as T
}
