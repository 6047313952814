import { MESSAGE_TYPE } from 'configuration'
import usePostMessage from 'hooks/usePostMessage'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { IAppState } from 'reducers'
import SessionProvider from 'session'
import * as Sentry from '@sentry/node'
import { isChineseRegion } from '../../utils/getRegion'

type ILiveChatContext = {
  openLiveChat: () => void
  isMobileApp: boolean
}

const LiveChatContext = createContext<ILiveChatContext>({
  openLiveChat: () => window.dispatchEvent(new Event('chr.checkLiveChatAvailableAgents')),
  isMobileApp: false,
})

export const useLiveChatContext = (): ILiveChatContext => useContext(LiveChatContext)

interface ILiveChatContainerProps {
  isMobileApp: boolean
}

const EmptyComponent = ({ children }: PropsWithChildren): JSX.Element => <>{children}</>

const LiveChatComponent = ({
  children,
  isMobileApp,
}: PropsWithChildren<ILiveChatContainerProps>): JSX.Element => {
  const { callPostMessage } = usePostMessage(isMobileApp)

  const [isLiveChatScriptLoaded, setIsLiveChatScriptLoaded] = useState(false)

  const isLoggedIn = useSelector((state: IAppState) => !!state.account.clientGuid)

  useEffect(() => {
    const liveChatReadyEvent = () => setIsLiveChatScriptLoaded(true)

    window.addEventListener('chr.liveChatReady', liveChatReadyEvent)

    return () => {
      window.removeEventListener('chr.liveChatReady', liveChatReadyEvent)
    }
  }, [])

  const {
    title,
    firstName: first_name,
    lastName: last_name,
    email,
    kycStatus: kyc_status,
    accountNumber: cos_id,
    phoneNumber: phone,
  } = useSelector((state: IAppState) => state.account)

  useEffect(() => {
    if (isLoggedIn && isLiveChatScriptLoaded && !!SessionProvider.getUserSession().accessToken) {
      const data = {
        title,
        first_name,
        last_name,
        email,
        kyc_status,
      } as {
        title: string
        first_name: string
        last_name: string
        email: string
        kyc_status: string
        cos_id?: string
        phone?: string
        [key: string]: string | undefined
      }
      if (cos_id) data['cos_id'] = cos_id
      if (phone) data['phone'] = phone

      const event = new CustomEvent('chr.updateLiveChatUserDetails', {
        detail: data,
      })
      window.dispatchEvent(event)

      Sentry.addBreadcrumb({
        category: 'Live Chat',
        message: 'User data has been pushed into Live Chat',
        level: Sentry.Severity.Info,
        data,
      })
    }
  }, [
    cos_id,
    email,
    first_name,
    isLoggedIn,
    kyc_status,
    last_name,
    phone,
    title,
    isLiveChatScriptLoaded,
  ])

  const openLiveChat = useCallback(() => {
    if (isMobileApp) {
      callPostMessage(MESSAGE_TYPE.NAVIGATION, 'live-chat')
    } else {
      const event = new Event('chr.checkLiveChatAvailableAgents')
      window.dispatchEvent(event)
    }
  }, [callPostMessage, isMobileApp])

  const liveChatContextValue = useMemo(
    () => ({ openLiveChat, isMobileApp }),
    [openLiveChat, isMobileApp]
  )

  return (
    <LiveChatContext.Provider value={liveChatContextValue}>
      <div className="chr-live-chat d-none">
        {/* @ts-ignore */}
        <chr-live-chat open-on-page-load="false"></chr-live-chat>
      </div>
      {children}
    </LiveChatContext.Provider>
  )
}

export const LiveChatContextProvider = ({
  children,
  isMobileApp,
}: PropsWithChildren<ILiveChatContainerProps>): JSX.Element => {
  if (isChineseRegion()) return <EmptyComponent>{children}</EmptyComponent>

  return <LiveChatComponent isMobileApp={isMobileApp}>{children}</LiveChatComponent>
}
