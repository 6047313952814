import { Action, ActionCreator } from 'redux'

export enum ACTION_TYPE {
  SET_REFERRER = 'CHR/REFERRER/SET_REFERRER',
}

export type ReferrerPayload = {
  payload: string
}

export type ReferrerAction = Action<ACTION_TYPE> & ReferrerPayload

export const createReferrerAction: ActionCreator<ReferrerAction> = (
  payload: string
): ReferrerAction => ({
  type: ACTION_TYPE.SET_REFERRER,
  payload,
})
