import { ACTION_TYPE, IActionType } from 'actions/kyc/types'
import { DOCUMENT_TYPE } from 'configuration'

export interface IKycState {
  uploading: boolean
  error: boolean
  documentTypeBeingUploaded?: DOCUMENT_TYPE
}

const initialState = {
  uploading: false,
  error: false,
  documentTypeBeingUploaded: undefined,
}

export const kycReducer = (state: IKycState = initialState, action: IActionType): IKycState => {
  switch (action.type) {
    case ACTION_TYPE.KYC_DOCUMENT_UPLOAD:
      return {
        ...state,
        uploading: true,
        error: false,
        documentTypeBeingUploaded: action.payload.documentType,
      }
    case ACTION_TYPE.KYC_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false,
        error: false,
        documentTypeBeingUploaded: undefined,
      }
    case ACTION_TYPE.KYC_DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        uploading: false,
        error: true,
        documentTypeBeingUploaded: undefined,
      }
    default:
      return state
  }
}
