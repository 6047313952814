import React, { HTMLProps, PropsWithChildren } from 'react'
import { logService } from 'utils/logService'

interface ExternalLinkProps extends HTMLProps<HTMLAnchorElement> {
  href: string
  /** en string for analytics tracking, should not be translated */
  track: string
}

export function ExternalLink({
  track,
  children,
  href,
  ...rest
}: PropsWithChildren<ExternalLinkProps>) {
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>
  ): void => {
    e.stopPropagation()
    e.preventDefault()
    logService.trackLink(e, track)
    window.location.assign(href)
  }

  return (
    <a {...rest} href={href} onClick={handleClick}>
      {children}
    </a>
  )
}
