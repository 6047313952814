import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { env } from 'next-runtime-env'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: env('NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING'),
    enableAutoRouteTracking: true, // option to log all route changes
    autoTrackPageVisitTime: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    enableCorsCorrelation: true,
    extensions: [reactPlugin],
    excludeRequestFromAutoTrackingPatterns: [
      /cdn\.cookielaw\.org/i,
      /onetrust\.com/i,
      /auth0\.com/i, // excluded due to the `request-context` header field is not allowed (CORS)
      /google-analytics\.com/i,
      /pinterest\.com/i,
      /omtrdc\.net/i,
      /acquire\.io/i,
      /demdex\.net/i,
    ],
  },
})

// do not load app insights in when running tests
if (typeof window !== 'undefined' && process.env.JEST_WORKER_ID === undefined) {
  appInsights.loadAppInsights()
  appInsights.trackPageView()
}

export { appInsights, reactPlugin }
