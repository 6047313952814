import * as Sentry from '@sentry/node'
import { EntryPoint } from 'configuration/entryPoints'
import { CookieProvider, SameSite } from 'infrastructure/isomorphic-cookies'
import environment from 'configuration/env'
import { appInsights } from 'configuration/appInsights'

type NotSet = undefined | null
export type StringOrNotSet = string | NotSet

interface KeyValueStorage<TKey, TValue> {
  save(key: TKey, value: TValue): void
  get(key: TKey): TValue | NotSet
  clear(): void
  remove?(key: TKey): void
}

interface Expirable {
  setExpiration(daysInFuture: number): void
}

type ExpirableKeyValueStore<TKey, TValue> = KeyValueStorage<TKey, TValue> & Expirable

interface CookieProviderSettable {
  setCookieProvider(provider: CookieProvider): void
}

export type StringKeyValueStore = KeyValueStorage<string, string>

export type ExpirableStringKeyValueStore = ExpirableKeyValueStore<string, string>

export type CookieStore = ExpirableStringKeyValueStore & CookieProviderSettable

const hasWindow = () => typeof window !== 'undefined'

class CookieStorage implements CookieStore {
  #_memoryStorage: Map<string, string>
  #_cookieName: string
  #_expires: number | undefined
  #_domain: string
  #_sameSite: SameSite
  #_cookieProvider: CookieProvider | undefined

  constructor({
    name,
    expires,
    domain,
    sameSite = SameSite.Lax,
  }: {
    name: string
    expires?: number
    domain: string
    sameSite?: SameSite
  }) {
    this.#_memoryStorage = new Map<string, string>()
    this.#_cookieName = name
    this.#_expires = expires
    this.#_domain = domain
    this.#_sameSite = sameSite
    this.#_cookieProvider = undefined

    this.updateKeyValueStoreFromCookie()
  }

  updateKeyValueStoreFromCookie(): void {
    if (!this.#_cookieProvider) return
    const cookieValue = this.#_cookieProvider.get(this.#_cookieName) || ''
    this.#_memoryStorage.clear()

    if (!cookieValue) return

    // Christie's cookie values are delimited by & and each item
    // therein is key=value separated
    cookieValue.split('&').forEach((item: string) => {
      const [key, value] = item.split('=')
      this.#_memoryStorage.set(key, value)
    })
  }

  writeCookie(): void {
    if (!this.#_cookieProvider) return
    const cookieValue = Array.from(this.#_memoryStorage.keys())
      .map((key) => `${key}=${this.#_memoryStorage.get(key)}`)
      .join('&')

    if (cookieValue) {
      this.#_cookieProvider.set(this.#_cookieName, cookieValue, {
        expires: this.#_expires,
        domain: this.#_domain,
        sameSite: this.#_sameSite,
      })
    } else {
      this.clear()
    }
  }

  setExpiration(daysInFuture: number): void {
    this.#_expires = daysInFuture
    this.writeCookie()
  }

  save(key: string, value: string): void {
    this.updateKeyValueStoreFromCookie()

    if (value) {
      this.#_memoryStorage.set(key, value)
    } else {
      this.#_memoryStorage.delete(key)
    }
    this.writeCookie()
  }

  get(key: string): StringOrNotSet {
    this.updateKeyValueStoreFromCookie()

    return this.#_memoryStorage.get(key)
  }

  clear() {
    if (!this.#_cookieProvider) return
    this.#_cookieProvider.remove(this.#_cookieName, this.#_domain)
  }

  setCookieProvider(cookieProvider: CookieProvider) {
    this.#_cookieProvider = cookieProvider
    this.updateKeyValueStoreFromCookie()
  }
}

class ChristiesCookieStorage implements CookieStore {
  #_webClientId: CookieStorage
  #_webClientId2: CookieStorage
  #_accessToken: CookieStorage
  #_refreshToken: CookieStorage

  constructor() {
    this.#_webClientId = new CookieStorage({
      name: 'WebClient_Id',
      domain: environment.cookieDomain,
    })
    this.#_webClientId2 = new CookieStorage({
      name: 'WebClient_Id2',
      expires: 1,
      domain: environment.cookieDomain,
    })
    this.#_accessToken = new CookieStorage({
      name: 'AccessToken',
      domain: environment.cookieDomain,
    })
    this.#_refreshToken = new CookieStorage({
      name: 'RefreshToken',
      expires: 1,
      domain: environment.cookieDomain,
    })
  }

  setExpiration(when: number): void {
    this.#_webClientId2.setExpiration(when)
    this.#_refreshToken.setExpiration(when)
  }

  save(key: string, value: string): void {
    switch (key) {
      case SessionKeys.UserAccessTokenKey:
        this.#_accessToken.save(key, value)
        break
      case SessionKeys.UserRefreshTokenKey:
        this.#_refreshToken.save(key, value)
        break
      case SessionKeys.CountryId:
        this.#_webClientId2.save(key, value)
        break
      case SessionKeys.ClientIdKey:
        this.#_webClientId.save(key, value)
        this.#_webClientId2.save(key, value)
        break
    }
  }

  get(key: string): StringOrNotSet {
    switch (key) {
      case SessionKeys.UserAccessTokenKey:
        return this.#_accessToken.get(key)
      case SessionKeys.UserRefreshTokenKey:
        return this.#_refreshToken.get(key)
      case SessionKeys.CountryId:
        return this.#_webClientId2.get(key)
      case SessionKeys.ClientIdKey:
        return this.#_webClientId.get(key) || this.#_webClientId2.get(key)
      default:
        return undefined
    }
  }

  clear(): void {
    this.#_webClientId.clear()
    this.#_webClientId2.clear()
    this.#_accessToken.clear()
    this.#_refreshToken.clear()
  }

  setCookieProvider(cookieProvider: CookieProvider) {
    this.#_webClientId.setCookieProvider(cookieProvider)
    this.#_webClientId2.setCookieProvider(cookieProvider)
    this.#_accessToken.setCookieProvider(cookieProvider)
    this.#_refreshToken.setCookieProvider(cookieProvider)
  }
}

class InMemoryStorage implements StringKeyValueStore {
  #_memoryStorage: Map<string, string>

  constructor() {
    this.#_memoryStorage = new Map<string, string>()
  }
  save(key: string, value: string): void {
    this.#_memoryStorage.set(key, value)
  }
  get(key: string): StringOrNotSet {
    return this.#_memoryStorage.get(key)
  }
  clear(): void {
    this.#_memoryStorage.clear()
  }
  remove(key: string): void {
    this.#_memoryStorage.delete(key)
  }
}

class SessionStorage implements StringKeyValueStore {
  save(key: string, value: string): void {
    if (hasWindow()) window.sessionStorage.setItem(key, value)
  }
  get(key: string): StringOrNotSet {
    return hasWindow() ? window.sessionStorage.getItem(key) : undefined
  }

  clear(): void {
    if (hasWindow()) window.sessionStorage.clear()
  }
  remove(key: string): void {
    if (hasWindow()) window.sessionStorage.removeItem(key)
  }
}

export const SessionKeys = {
  ApplicationAccessTokenKey: 'ApplicationAccessToken',
  ClientIdKey: 'ClientGUID',
  UserAccessTokenKey: 'access_token',
  UserRefreshTokenKey: 'refresh_token',
  CountryId: 'CountryID',
  EntryPointKey: 'EntryPoint',
}

export type UserSession = {
  clientId?: StringOrNotSet
  accessToken?: StringOrNotSet
  refreshToken?: StringOrNotSet
  countryId?: StringOrNotSet
}

export class SessionProvider {
  #_applicationTokenStorage: StringKeyValueStore
  #_userTokenStorage: CookieStore

  constructor(
    applicationTokenStorage: StringKeyValueStore = typeof window === 'undefined'
      ? new InMemoryStorage() // Server side
      : new SessionStorage(), // Client side
    userTokenStorage: CookieStore = new ChristiesCookieStorage()
  ) {
    if (!applicationTokenStorage) {
      throw new Error('Application token storage cannot be null or undefined')
    }

    if (!userTokenStorage) {
      throw new Error('User token storage cannot be null or undefined')
    }

    this.#_applicationTokenStorage = applicationTokenStorage
    this.#_userTokenStorage = userTokenStorage
  }

  setApplicationSession(token: string): void {
    this.#_applicationTokenStorage.save(SessionKeys.ApplicationAccessTokenKey, token)
  }

  getApplicationSession(): StringOrNotSet {
    return this.#_applicationTokenStorage.get(SessionKeys.ApplicationAccessTokenKey)
  }

  clearApplicationSession(): void {
    this.#_applicationTokenStorage.clear()
  }

  setUserSession(userSession: UserSession): void {
    const userId = (userSession.clientId ||
      this.#_userTokenStorage.get(SessionKeys.ClientIdKey)) as string

    this.#_userTokenStorage.save(SessionKeys.ClientIdKey, userId)

    Sentry.setUser({
      id: userId,
    })
    appInsights.setAuthenticatedUserContext(userId, undefined, true)

    if (typeof window !== 'undefined') {
      //@ts-ignore
      window.chrGlobal = {
        is_authenticated: true,
      }
    }
    this.#_userTokenStorage.save(
      SessionKeys.UserAccessTokenKey,
      (userSession.accessToken ||
        this.#_userTokenStorage.get(SessionKeys.UserAccessTokenKey)) as string
    )
    this.#_userTokenStorage.save(
      SessionKeys.UserRefreshTokenKey,
      (userSession.refreshToken ||
        this.#_userTokenStorage.get(SessionKeys.UserRefreshTokenKey)) as string
    )
    this.#_userTokenStorage.save(
      SessionKeys.CountryId,
      (userSession.countryId || this.#_userTokenStorage.get(SessionKeys.CountryId)) as string
    )
  }

  getUserSession(): UserSession {
    return {
      clientId: this.#_userTokenStorage.get(SessionKeys.ClientIdKey),
      accessToken: this.#_userTokenStorage.get(SessionKeys.UserAccessTokenKey),
      refreshToken: this.#_userTokenStorage.get(SessionKeys.UserRefreshTokenKey),
      countryId: this.#_userTokenStorage.get(SessionKeys.CountryId),
    }
  }

  slideUserSessionExpiry(): void {
    this.#_userTokenStorage.setExpiration(1)
  }

  clearUserSession(): void {
    this.#_userTokenStorage.clear()
    if (typeof window !== 'undefined') {
      //@ts-ignore
      window.chrGlobal = {
        is_authenticated: false,
      }
    }
  }

  setCookieProvider(cookieProvider: CookieProvider): void {
    this.#_userTokenStorage.setCookieProvider(cookieProvider)
    const userId = this.getUserSession().clientId || undefined

    Sentry.setUser({
      id: userId,
    })
    appInsights.setAuthenticatedUserContext(userId as string, undefined, true)
  }

  getEntryPoint(): StringOrNotSet {
    return this.#_applicationTokenStorage.get(SessionKeys.EntryPointKey)
  }

  setEntryPoint(entryPoint: EntryPoint): void {
    this.#_applicationTokenStorage.save(SessionKeys.EntryPointKey, entryPoint)
  }

  clearEntryPoint(): void {
    this.#_applicationTokenStorage.remove &&
      this.#_applicationTokenStorage.remove(SessionKeys.EntryPointKey)
  }
}

export default new SessionProvider()

export const GBGSessionStore = new InMemoryStorage()
