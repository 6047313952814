import { Button } from 'components/Button'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import ReactModal from 'react-modal'
import styles from './index.module.scss'

export interface IModalProps {
  isOpen: boolean
  closeModal: () => void
  closeCta?: string
  heading: string
  appElement?: string
}

const Modal: FunctionComponent<PropsWithChildren<IModalProps>> = ({
  isOpen,
  closeModal,
  closeCta = '',
  children,
  heading,
  appElement = '#__next',
}) => {
  ReactModal.setAppElement(appElement)
  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.modal}
      overlayClassName={styles.overlay}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
    >
      <div className={styles.header}>
        <button aria-label={'Close'} onClick={closeModal} className={styles.header__close}>
          <i className="global__icon global__icon--plus" />
        </button>
      </div>
      <div className={styles.content}>
        <h2>{heading}</h2>
        <p>{children}</p>
      </div>
      {closeCta !== '' && (
        <div className={styles.footer}>
          <Button onClick={closeModal} buttonText={closeCta} />
        </div>
      )}
    </ReactModal>
  )
}

export default Modal
