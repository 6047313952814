import { useEffect, useState } from 'react'

const ScriptLoader = ({
  url,
  isModule,
  onError,
}: {
  url: string
  isModule: boolean
  onError: (url: string) => void
}): JSX.Element | null => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    let script: HTMLScriptElement
    if (!isLoaded && typeof window != 'undefined' && window.document) {
      script = window.document.createElement('script')
      script.onerror = () => {
        onError(url)
      }
      script.onload = () => {
        setIsLoaded(true)
      }
      script.src = url

      if (isModule) {
        script.type = 'module'
      } else {
        script.noModule = true
      }

      document.head.appendChild(script)
    }

    return () => {
      if (isLoaded && typeof window != 'undefined' && window.document) {
        // eslint-disable-next-line no-console
        console.info('Removing script from head', url)
        document.head.removeChild(script)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default ScriptLoader
