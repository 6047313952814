import { AxiosResponse } from 'axios'
import { sitecoreApiClient } from 'infrastructure/sitecore-client'
import { SupportedLanguages } from '../configuration/translations'
import { ILabel } from '../shared/labels'
import { ISettingsType } from '../models/settings'
import environment from 'configuration/env'

interface TranslationsType {
  dictionary: ILabel[]
  settings: ISettingsType
}

const getTranslationsServiceUrl = (language: SupportedLanguages): string =>
  `${environment.dotComUrl}/api/discoverywebsite/account/registration?language=${language}`

class TranslationsService {
  get(language: SupportedLanguages = SupportedLanguages.EN): Promise<TranslationsType> {
    return sitecoreApiClient
      .get<
        unknown,
        AxiosResponse<TranslationsType>
      >(getTranslationsServiceUrl(language), {}, 'GET /discoverywebsite/account/registration')
      .then((response) => {
        return response.data
      })
  }
}

export default new TranslationsService()
