import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import environment, { isProductionBuild } from 'configuration/env'
import SessionProvider from 'session'
import { env } from 'next-runtime-env'

export const initSentry = (): void => {
  if (env('NEXT_PUBLIC_SENTRY_DSN')) {
    const integrations = []
    if (process.env.NEXT_IS_SERVER === 'true' && env('NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR')) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame: any) => {
            frame.filename = frame.filename.replace(
              env('NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR'),
              'app:///'
            )
            frame.filename = frame.filename.replace('.next', '_next')
            return frame
          },
        })
      )
    }

    integrations.push(new TracingIntegrations.BrowserTracing())

    const [baseEnvironment, region] = environment.name.split('_')

    Sentry.init({
      enabled: isProductionBuild() && process.env.NEXT_PHASE !== 'phase-production-build',
      integrations,
      dsn: env('NEXT_PUBLIC_SENTRY_DSN'),
      release: `account-creation@${environment.buildId}`,
      environment: baseEnvironment,
      tracesSampleRate: environment.tracesSampleRate,
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
      denyUrls: [
        /assets\/discovery\.project\.website\/v2020\/design-library/i,
        /cdn\.cookielaw\.org/i,
        /onetrust\.com/i,
        /mmtro\.com/i,
        /pinterest\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      // add an application entry point as a tag + ignore all 401s
      beforeSend: (event) => {
        const entryPoint = SessionProvider.getEntryPoint()
        if (entryPoint) {
          event.tags = { ...event.tags, region, entryPoint }
        }

        return event.message?.includes('401') ? null : event
      },
    })
  }
}
