export function MaximalHeader() {
  return (
    <div className="chr-header-footer">
      {/* @ts-ignore  */}
      <chr-header data-namespace="header"></chr-header>

      {/* @ts-ignore */}
      <chr-modal-provider></chr-modal-provider>
    </div>
  )
}
