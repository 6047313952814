import { HTMLAttributes } from 'react'
import icons, { Icons } from './icons'
import styles from './index.module.scss'
import classNames from 'classnames'

interface IconProps extends HTMLAttributes<HTMLElement> {
  icon: Icons
  variant?: 'dark' | 'light' | 'red' | 'green' | ''
  disabled?: boolean
}

export function Icon({ icon, variant = '', disabled = false, ...rest }: IconProps) {
  return (
    <i
      {...rest}
      className={classNames(
        'global__icon',
        `global__icon--${icon}`,
        !disabled ? variant : '',
        { [styles.disabled]: disabled },
        rest.className
      )}
      aria-hidden="true"
      dangerouslySetInnerHTML={{ __html: icons[icon] }}
    />
  )
}
