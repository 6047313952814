export const SSR_PAGES = [
  '/',
  '/account-benefits',
  '/signup',
  '/welcome',
  '/confirm-details',
  '/address',
  '/id-verification',
  '/security-questions',
  '/complete',
  '/api/address-lookup',
]
