import { MinimalHeader, MinimalHeaderProps } from './MinimalHeader'
import { MaximalHeader } from './MaximalHeader'

export enum HeaderSize {
  Minimal = 'minimal',
  Maximal = 'maximal',
}

interface HeaderProps extends MinimalHeaderProps {
  size: HeaderSize
}

export function Header({ size, hideWhenLoggedIn, userLoggedIn }: HeaderProps) {
  return (
    <header>
      {size === HeaderSize.Maximal ? (
        <MaximalHeader />
      ) : (
        <MinimalHeader hideWhenLoggedIn={hideWhenLoggedIn} userLoggedIn={userLoggedIn} />
      )}
    </header>
  )
}
