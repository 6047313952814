import { HYDRATE } from 'next-redux-wrapper'
import { accountReducer, IAccountState } from 'reducers/account'
import { authenticationReducer, IAuthenticationState } from 'reducers/authentication'
import { IKycState, kycReducer } from 'reducers/kyc'
import { referrerReducer, ReferrerState } from 'reducers/referrer'
import { Action, combineReducers, Reducer } from 'redux'
import { addressReducer, IAddressState } from './address'

export interface IAppState {
  authentication: IAuthenticationState
  account: IAccountState
  kyc: IKycState
  referrer: ReferrerState
  address: IAddressState
}

const combinedReducer: Reducer<IAppState, Action<any>> = combineReducers({
  authentication: authenticationReducer,
  account: accountReducer,
  kyc: kycReducer,
  referrer: referrerReducer,
  address: addressReducer,
})

const reducer = (state: IAppState, action: Action<any>): IAppState => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      // @ts-ignore
      ...action.payload, // apply delta from hydration
    }
    return nextState
  } else {
    if (action.type === 'CHR/RESET') {
      // @ts-ignore
      state = undefined
    }

    return combinedReducer(state, action)
  }
}

export default reducer
