import { useEffect, useState } from 'react'

const StyleLoader = ({
  url,
  onError,
}: {
  url: string
  onError: (url: string) => void
}): JSX.Element | null => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    let styles: HTMLLinkElement
    if (!isLoaded && typeof window != 'undefined' && window.document) {
      styles = window.document.createElement('link')
      styles.onerror = () => {
        onError(url)
      }
      styles.onload = () => {
        setIsLoaded(true)
      }
      styles.href = url
      styles.rel = 'stylesheet'

      document.head.appendChild(styles)
    }

    return () => {
      if (isLoaded && typeof window != 'undefined' && window.document) {
        // eslint-disable-next-line no-console
        console.info('Removing styles from head', url)
        document.head.removeChild(styles)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default StyleLoader
