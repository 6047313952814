import React from 'react'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'
import styles from './index.module.scss'
import classNames from 'classnames'

export function ChrLiveChatModal() {
  const { getTranslationLiteral: tl, getTranslation: t } = useTranslations(
    TranslationKeyPrefix.LiveChat
  )

  return (
    <div className="chr-header-footer">
      {/* @ts-ignore */}
      <chr-inline-modal-provider modal-title={tl('no_agents.title')}>
        {/* @ts-ignore */}
        <chr-modal-content slot="live-chat-no-agents-modal" id="live-chat-no-agents-modal">
          <div className={classNames(styles.modal__body, 'd-flex flex-column mb-5 chr-body')}>
            <p data-testid="we-are-sorry-paragraph">{tl('no_agents.text1')}</p>
            <p className={styles.text__link} data-testid="contact-us-paragraph">
              {t('no_agents.text2')}.
            </p>
          </div>
          {/* @ts-ignore */}
        </chr-modal-content>
        {/* @ts-ignore */}
      </chr-inline-modal-provider>
    </div>
  )
}
