import React from 'react'
import { ITranslation } from 'translations'

const TranslationContext = React.createContext<{
  translations: Array<ITranslation>
  isMobile: boolean
}>({
  translations: [],
  isMobile: false,
})

export default TranslationContext
