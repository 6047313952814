import { Action } from 'redux'
import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects'

import {
  ACTION_TYPE as ACCOUNT_ACTION_TYPE,
  setKycAddressUploadStatus,
  setKycIdUploadStatus,
} from 'actions/account'
import {
  ACTION_TYPE,
  KycDocumentUpload,
  KycDocumentUploadFailure,
  KycDocumentUploadSuccess,
} from 'actions/kyc/types'
import { KycDocumentUploadFailureAction, KycDocumentUploadSuccessAction } from 'actions/kyc'

import uploadService, { DocumentUploadApiResponse } from 'services/id-verification'
import { DOCUMENT_TYPE, KYC_DOCUMENT_STATUS } from '../../configuration'

export const sagas = [takeEvery(ACTION_TYPE.KYC_DOCUMENT_UPLOAD, uploadDocument)]

export function* uploadDocument(
  action: KycDocumentUpload
): Generator<
  | CallEffect<void>
  | PutEffect<KycDocumentUploadFailure>
  | PutEffect<Action<ACCOUNT_ACTION_TYPE>>
  | PutEffect<KycDocumentUploadSuccess>
  | unknown
> {
  try {
    const document: DocumentUploadApiResponse = (yield call(
      uploadService.upload,
      action.payload.file,
      action.payload.documentType
    ) as unknown) as DocumentUploadApiResponse
    if (document?.ResponseMessage === 'Success') {
      yield put(KycDocumentUploadSuccessAction())
      if (action.payload.documentType === DOCUMENT_TYPE.KYC) {
        if (document?.valid_id_document === KYC_DOCUMENT_STATUS.PASS) {
          if (document?.summary === KYC_DOCUMENT_STATUS.PASS) {
            yield put(setKycIdUploadStatus(ACCOUNT_ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_SUCCESS))
          } else {
            yield put(
              setKycIdUploadStatus(ACCOUNT_ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_HALF_SUCCESS)
            )
          }
        } else {
          yield put(setKycIdUploadStatus(ACCOUNT_ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_FAIL))
        }
      }
      if (action.payload.documentType === DOCUMENT_TYPE.POF) {
        if (document?.valid_poa_document === KYC_DOCUMENT_STATUS.PASS) {
          yield put(
            setKycAddressUploadStatus(ACCOUNT_ACTION_TYPE.SET_KYC_ADDRESS_UPLOAD_STATUS_SUCCESS)
          )
        } else {
          yield put(
            setKycAddressUploadStatus(ACCOUNT_ACTION_TYPE.SET_KYC_ADDRESS_UPLOAD_STATUS_FAIL)
          )
        }
      }
    } else {
      yield put(KycDocumentUploadFailureAction())
    }
  } catch (error) {
    yield put(KycDocumentUploadFailureAction())
  }
}
