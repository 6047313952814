import * as Sentry from '@sentry/node'
import { Severity } from '@sentry/types'

/**
 * Resolves Sentry severity by a HTTP status code.
 * @param httpStatus HTTP status code
 */
export const resolveSentrySeverity = (httpStatus?: number): Severity => {
  if (!httpStatus || httpStatus >= 500) {
    return Sentry.Severity.Error
  } else if (httpStatus >= 400) {
    return Sentry.Severity.Warning
  } else {
    return Sentry.Severity.Info
  }
}
