import { defineEnvConfig } from '../defineEnvConfig'

export function createTestConfig(isCn: boolean) {
  return defineEnvConfig({
    auth0Url: 'https://sit-christies-account.us.auth0.com',
    auth0Audience: 'https://sit.christies.com/general-service-api',
    cookieDomain: `.christies.com${isCn ? '.cn' : ''}`,
    dotComUrl: `https://integration.christies.com${isCn ? '.cn' : ''}`,
    thisAppUrl: `https://signup-test.christies.com${isCn ? '.cn' : ''}`,
    dslAssetsUrl: `https://dsl.assets.christies.com${isCn ? '.cn' : ''}`,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    afdUrl: `https://api-nonprod.christies.com${isCn ? '.cn' : ''}/dev`,
    apimUrl: 'https://devapim.christies.com/sit',
  })
}
