import TranslationContext from 'components/Translations/TranslationContext'
import environment from 'configuration/env'
import { MESSAGE_TYPE } from 'configuration/mobileApp'
import { SSR_PAGES } from 'configuration/ssrPages'
import Link from 'next/link'
import { ReactNode, useContext } from 'react'
import reactStringReplace from 'react-string-replace'
import { ITranslation } from 'translations'
import usePostMessage from './usePostMessage'
import { useLiveChatContext } from 'containers/LiveChat'

export enum TranslationKeyPrefix {
  AccountCreation = 'account_creation',
  SignIn = 'signin',
  AccountBenefits = 'account_benefits',
  SignUpCommon = 'sign_up.common',
  SignUpStep1 = 'sign_up.step1',
  SignUpStep2 = 'sign_up.step2',
  SignUpStep3 = 'sign_up.step3',
  KYCCommon = 'kyc',
  Welcome = 'welcome',
  ConfirmDetails = 'confirm_details',
  PersonalDetails = 'personal_details',
  IDVerification = 'id_verification',
  SecurityQuestions = 'security_questions',
  Complete = 'complete',
  Meta = 'meta',
  Input = 'input',
  LiveChat = 'live_chat',
  Error = 'error',
}

const isThisAppUrl = (url: string) =>
  SSR_PAGES.some((page) => page.toLowerCase() === url.toLowerCase())

const isDownload = (url: string) => url.includes('.pdf')

const UNDEFINED_TEXT = '!! UNDEFINED TEXT !!'
const UNDEFINED_LINK = '!! UNDEFINED LINK !!'

const LIVE_CHAT_LINK = '@LIVE_CHAT'

const MOBILE_NAVIGATE_KEY: { [key: string]: string } = {
  '@CLIENT_SERVICES': 'faq',
  '@TERMS_AND_CONDITIONS': 'terms-of-use',
  '@PRIVACY_POLICY': 'privacy-notice',
  '@SIGN_IN': 'close',
  '@HELP': 'faq',
  '@MY_CHRISTIES': 'Account settings',
}

const getOnClick = (
  match: string,
  urlForMatch: string,
  isMobile: boolean,
  sendMobileEvent: (messageType: MESSAGE_TYPE, navigateTo: string) => void
): ((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined => {
  if (!isMobile) return undefined

  return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    sendMobileEvent(MESSAGE_TYPE.NAVIGATION, MOBILE_NAVIGATE_KEY[match] ?? urlForMatch)
  }
}

const processLinkReplacements = (
  translations: Array<ITranslation>,
  isMobile: boolean,
  sendMobileEvent: (messageType: MESSAGE_TYPE, navigateTo: string) => void,
  openLiveChat: () => void,
  translation: string
): ReactNode[] | JSX.Element => {
  if (!/@[A-Z0-9_]+/.test(translation)) {
    return <>{translation}</>
  }

  return reactStringReplace(translation, /(@[A-Z0-9_]+)/g, (match: string, index: number) => {
    const textForMatch = getTranslationStringLiteral(translations, `links.${match}.text`)

    if (match === LIVE_CHAT_LINK) {
      return (
        <button
          className="copy-link"
          key={`live-chat-${index}`}
          tabIndex={0}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            openLiveChat()
          }}
        >
          {textForMatch}
        </button>
      )
    }

    const urlForMatch = getTranslationStringLiteral(translations, `links.${match}.url`)

    if (!urlForMatch || !textForMatch) {
      return <abbr title={match}>{UNDEFINED_LINK}</abbr>
    }

    if (isThisAppUrl(urlForMatch) && !isMobile) {
      return (
        <Link href={urlForMatch} passHref key={match} className="copy-link">
          {textForMatch}
        </Link>
      )
    } else if (isDownload(urlForMatch)) {
      return (
        <a className="copy-link" href={urlForMatch} target="_blank" rel="noreferrer" key={match}>
          {textForMatch}
        </a>
      )
    } else {
      const fullUrl = `${environment.dotComUrl}${urlForMatch}`
      const onClick = getOnClick(match, fullUrl, isMobile, sendMobileEvent)

      const anchorProps = {
        onClick,
        'data-testid': `${match}-${index}`,
        href: fullUrl,
        target: '_blank',
        className: 'copy-link',
      }

      return (
        <a key={`${match}-${index}`} {...anchorProps}>
          {textForMatch}
        </a>
      )
    }
  })
}

const getTranslationStringLiteral = (translations: Array<ITranslation>, key: string): string => {
  const translation = translations.find((translation: ITranslation) => translation.key === key)

  if (!translation) return UNDEFINED_TEXT

  return translation.value
}

const getTranslationNode = (
  translations: Array<ITranslation>,
  isMobile: boolean,
  sendMobileEvent: (messageType: MESSAGE_TYPE, navigateTo: string) => void,
  openLiveChat: () => void,
  key: string
): ReactNode[] | JSX.Element => {
  const translation = getTranslationStringLiteral(translations, key)

  if (translation === UNDEFINED_TEXT) {
    return <abbr title={key}>{UNDEFINED_TEXT}</abbr>
  }

  return processLinkReplacements(translations, isMobile, sendMobileEvent, openLiveChat, translation)
}

const translationExists = (translations: Array<ITranslation>, key: string) =>
  getTranslationStringLiteral(translations, key) !== UNDEFINED_TEXT

const getAllTranslationLiteralsByPrefix = (
  translations: Array<ITranslation>,
  prefix: string
): Array<ITranslation> => {
  return translations.filter((translation: ITranslation) => translation.key.startsWith(prefix))
}

export type TranslationFunction = (key: string) => ReactNode[] | JSX.Element

export type TranslationLiteralFunction = (key: string) => string

type UseTranslationsType = {
  getTranslation: TranslationFunction
  getTranslationLiteral: TranslationLiteralFunction
  hasTranslation: (key: string) => boolean
  // getAllTranslationsByPrefix: (
  //   prefix: string
  // ) => Array<React.ReactNodeArray | JSX.Element>;
  getAllTranslationLiteralsByPrefix: (prefix: string) => Array<ITranslation>
  getUrlByLinkKey: (key: string) => string
  getTextByLinkKey: (key: string) => string
}

export const useTranslations = (prefix: TranslationKeyPrefix): UseTranslationsType => {
  const { translations, isMobile } = useContext(TranslationContext)
  const { callPostMessage } = usePostMessage(isMobile)
  const { openLiveChat } = useLiveChatContext()

  return {
    getTranslation: (key: string) =>
      getTranslationNode(translations, isMobile, callPostMessage, openLiveChat, `${prefix}.${key}`),
    getTranslationLiteral: (key: string) =>
      getTranslationStringLiteral(translations, `${prefix}.${key}`),
    hasTranslation: (key: string) => translationExists(translations, `${prefix}.${key}`),
    // getAllTranslationsByPrefix: (prefix: string) =>
    //   getAllTranslationNodesByPrefix(
    //     translations,
    //     isMobile,
    //     callPostMessage,
    //     prefix
    //   ),
    getAllTranslationLiteralsByPrefix: (key: string) =>
      getAllTranslationLiteralsByPrefix(translations, `${prefix}.${key}`),
    getUrlByLinkKey: (key: string) => getTranslationStringLiteral(translations, `links.${key}.url`),
    getTextByLinkKey: (key: string) =>
      getTranslationStringLiteral(translations, `links.${key}.text`),
  }
}
