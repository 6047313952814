import accountActions from 'actions/accountCreation'
import { ACTION_TYPE as MOBILE_ACTION } from 'actions/mobileApp/types'
import {
  ACTION_TYPE as ACCOUNT_ACTION_TYPE,
  IUpdatePreferencesAction,
  IUpdatePreferencesFailureAction,
  IUpdatePreferencesSuccessAction,
} from 'actions/accountCreation/types'
import authenticationActions from 'actions/authentication'
import {
  ACTION_TYPE,
  IChangeStepAction,
  ICheckEmailAction,
  ICheckEmailFailureAction,
  ICheckEmailSuccessAction,
  ILoginAction,
  ILoginFailureAction,
  ILoginSuccessAction,
  IRegisterUserAction,
  IRegisterUserFailureAction,
  IRegisterUserSuccessAction,
  IActivateAccountAction,
} from 'actions/authentication/types'
import { PAGE_CONFIG } from 'configuration/analytics'
import { ROUTE } from 'configuration/routes'
import { postMessageSession } from 'hooks/usePostMessage'
import router from 'next/router'
import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeEvery,
  select,
  SelectEffect,
} from 'redux-saga/effects'
import accountService, {
  CommunicationPreferences,
  CreateAccountApiResponse,
  CreateAccountDetails,
  EmailExistsApiResponse,
  UpdatePasswordApiResponse,
} from 'services/account'
import loginService, { LoginApiResponse } from 'services/login'
import SessionProvider from 'session'
import { logService } from 'utils/logService'
import type { IAppState } from 'reducers'
import { appendLangParamToUrl } from 'utils/urls'

export const sagas = [
  takeEvery(ACTION_TYPE.USER_LOGIN, userAuthenticate),
  takeEvery(ACTION_TYPE.USER_LOGIN_SUCCESS, userAuthenticateSuccess),
  takeEvery(ACTION_TYPE.USER_LOGIN_FAILURE, userAuthenticateFailure),

  takeEvery(ACTION_TYPE.CHECK_EMAIL, checkEmail),

  takeEvery(ACTION_TYPE.USER_REGISTER, userRegister),
  takeEvery(ACTION_TYPE.USER_REGISTER_SUCCESS, userRegisterSuccess),
  takeEvery(ACTION_TYPE.USER_REGISTER_FAILURE, userRegisterFailure),

  takeEvery(ACCOUNT_ACTION_TYPE.UPDATE_PREFERENCES, updatePreferences),

  takeEvery(ACTION_TYPE.ACTIVATE_ACCOUNT, activateAccount),
]

export function* userAuthenticate(
  action: ILoginAction
): Generator<
  | CallEffect<LoginApiResponse>
  | PutEffect<ILoginFailureAction>
  | PutEffect<ILoginSuccessAction>
  | CallEffect<unknown>
  | SelectEffect
> {
  try {
    yield call(loginService.login, action.payload.email, action.payload.password)

    yield put(authenticationActions.userAuthenticateSuccessAction())

    const returnUrl = (yield select((state: IAppState) => state.referrer.url)) as string
    const language = (yield select((state: IAppState) => state.authentication.language)) as string
    yield call((url) => window.location.assign(url), appendLangParamToUrl(returnUrl, language))
  } catch (error) {
    const errorType: string =
      error.response?.data?.errors?.[0]?.errorCode === 429 ||
      error.response?.data?.errors?.[0]?.errorDescription.includes('Too many attempts')
        ? 'account.locked'
        : 'account.invalid'
    yield put(authenticationActions.userAuthenticateFailureAction(errorType))
  }
}

export function* userAuthenticateSuccess(): Generator<CallEffect<unknown>> {
  yield call([logService, 'trackForm'], {
    type: 'success',
    details: PAGE_CONFIG.SIGN_IN,
  })
}

export function* userAuthenticateFailure(): Generator<CallEffect<unknown>> {
  yield call([logService, 'trackForm'], {
    type: 'error',
    details: PAGE_CONFIG.SIGN_IN,
    errors: { generic: 'server validation error' },
  })
}

export function* checkEmail(
  action: ICheckEmailAction
): Generator<
  | CallEffect<EmailExistsApiResponse>
  | PutEffect<ICheckEmailFailureAction>
  | PutEffect<ICheckEmailSuccessAction>
  | PutEffect<IChangeStepAction>
> {
  try {
    const { email_address_exists } = (yield call(
      accountService.emailExists,
      action.payload.email,
      action.payload.h_captcha_response_token
    )) as EmailExistsApiResponse

    if (email_address_exists) {
      throw new Error('emailexists')
    }

    yield put(authenticationActions.checkEmailSuccessAction())
    yield put(authenticationActions.changeStepAction(2))
  } catch (error) {
    yield put(authenticationActions.checkEmailFailureAction(error.message))
  }
}

export function* userRegister(
  action: IRegisterUserAction
): Generator<
  | CallEffect<CreateAccountApiResponse>
  | PutEffect<IRegisterUserSuccessAction>
  | PutEffect<IRegisterUserFailureAction>
  | PutEffect<IChangeStepAction>
  | PutEffect<any>
> {
  try {
    const createAccountpayload: CreateAccountDetails = {
      userName: action.payload.email,
      password: action.payload.password,
      title: action.payload.title,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      geoCountryCode: action.payload.geocountrycode,
      accountNumber: action.payload.account_number,
      language_id: action.payload.language_id,
    }
    yield call(accountService.createAccount, createAccountpayload)
    if (action.payload.isMobileApp && typeof window !== 'undefined') {
      //@ts-ignore
      const { accessToken, refreshToken } = yield call([SessionProvider, 'getUserSession'])

      const data = postMessageSession(accessToken, refreshToken)
      yield put({
        type: MOBILE_ACTION.POST_MESSAGE,
        payload: data,
      })
    }
    yield put(authenticationActions.userRegisterSuccessAction())
    yield put(authenticationActions.changeStepAction(3))
  } catch (error) {
    yield put(authenticationActions.userRegisterFailureAction(action.payload.error_message))
  }
}

export function* activateAccount(
  action: IActivateAccountAction
): Generator<
  CallEffect<UpdatePasswordApiResponse> | PutEffect<IChangeStepAction> | PutEffect<any>
> {
  try {
    yield call(accountService.activateAccount, action.payload.ac, action.payload.password)
    if (action.payload.isMobileApp && typeof window !== 'undefined') {
      //@ts-ignore
      const { accessToken, refreshToken } = yield call([SessionProvider, 'getUserSession'])

      const data = postMessageSession(accessToken, refreshToken)
      yield put({
        type: MOBILE_ACTION.POST_MESSAGE,
        payload: data,
      })
    }
    yield put(authenticationActions.changeStepAction(3)) // We go directly to step 3 here
  } catch (error) {
    yield put(authenticationActions.checkEmailFailureAction('Updating password failed'))
  }
}

export function* userRegisterSuccess(): Generator<CallEffect<unknown>> {
  yield call([logService, 'trackForm'], {
    type: 'success',
    details: PAGE_CONFIG.CREATE_ACCOUNT_2,
  })
}

export function* userRegisterFailure(): Generator<CallEffect<unknown>> {
  yield call([logService, 'trackForm'], {
    type: 'error',
    details: PAGE_CONFIG.CREATE_ACCOUNT_2,
    errors: { generic: 'server validation error' },
  })
}

export function* updatePreferences(
  action: IUpdatePreferencesAction
): Generator<
  | CallEffect<void>
  | PutEffect<IUpdatePreferencesSuccessAction>
  | PutEffect<IUpdatePreferencesFailureAction>
  | CallEffect<unknown>
> {
  const { error_message, data: preferences } = action.payload
  try {
    const payload: CommunicationPreferences = {
      optinEmailArtNews: preferences.optin_email_art_news,
      optinEmailAuctionUpdates: preferences.optin_email_auction_updates,
      optinEmailConsignmentValuations: preferences.optin_email_consignment_valuations,
      optinEmailEvents: preferences.optin_email_events,
      optinEmailLotAlerts: preferences.optin_email_lot_alerts,
      optinEmailEducation: preferences.optin_email_education,
      optinEmailRealEstate: preferences.optin_email_real_estate,
    }
    yield call(accountService.updateCommunicationPreferences, payload)

    yield put(accountActions.updatePreferencesSuccessAction())
    yield call(router.push, ROUTE.WELCOME)
  } catch {
    yield put(accountActions.updatePreferencesFailureAction(error_message))
  }
}

/**
 * For account completion steps you will need the following tracking
 *
 * For errors: (if you have specific errors include them)
     yield call([logService, "trackForm"], {
      step: "kyc | step 1",
      type: "error",
      errors: { generic: "server validation error" }
    });
 *
 * For successful creation of account
    yield call([logService, "trackForm"], {
      step: "kyc",
      customEvent: "full_registration",
      type: "success"
    });
 */
