import { all, AllEffect, ForkEffect } from 'redux-saga/effects'
import { sagas as authSagas } from 'sagas/authentication'
import { sagas as kycSagas } from 'sagas/kyc'
import { sagas as accountSagas } from './accountSagas'
import { sagas as contactDetailsSagas } from './contact-details'
import { sagas as mobileSagas } from './mobile'

export default function* rootWatcher(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([...authSagas, ...accountSagas, ...kycSagas, ...contactDetailsSagas, ...mobileSagas])
}
