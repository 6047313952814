import { IThirdStepValues } from 'models/forms'
import { ContactDetailsApiResponse, ContactDetailsPayload } from 'services/contact-details'

export enum ACTION_TYPE {
  UPDATE_PREFERENCES = 'UPDATE_PREFERENCES',
  UPDATE_PREFERENCES_SUCCESS = 'UPDATE_PREFERENCES_SUCCESS',
  UPDATE_PREFERENCES_FAILURE = 'UPDATE_PREFERENCES_FAILURE',

  SAVE_CONTACT_DETAILS = 'CHR/CONTACT/SAVE_CONTACT_DETAILS',
  SAVE_CONTACT_DETAILS_SUCCESS = 'CHR/CONTACT/SAVE_CONTACT_DETAILS_SUCCESS',
  SAVE_CONTACT_DETAILS_FAILURE = 'CHR/CONTACT/SAVE_CONTACT_DETAILS_FAILURE',
}

export interface ISaveContactDetailsAction {
  type: typeof ACTION_TYPE.SAVE_CONTACT_DETAILS
  payload: ContactDetailsPayload & { errorMessage: string }
}

export interface ISaveContactDetailsSuccessAction {
  type: typeof ACTION_TYPE.SAVE_CONTACT_DETAILS_SUCCESS
  payload: ContactDetailsApiResponse
}

export interface ISaveContactDetailsFailureAction {
  type: typeof ACTION_TYPE.SAVE_CONTACT_DETAILS_FAILURE
  payload: {
    error: string
  }
}

export interface IUpdatePreferencesAction {
  type: typeof ACTION_TYPE.UPDATE_PREFERENCES
  payload: {
    data: IThirdStepValues
    error_message: string
  }
}

export interface IUpdatePreferencesSuccessAction {
  type: typeof ACTION_TYPE.UPDATE_PREFERENCES_SUCCESS
}

export interface IUpdatePreferencesFailureAction {
  type: typeof ACTION_TYPE.UPDATE_PREFERENCES_FAILURE
  payload: {
    error: string
  }
}

export type IActionType =
  | IUpdatePreferencesAction
  | IUpdatePreferencesSuccessAction
  | IUpdatePreferencesFailureAction
  | ISaveContactDetailsAction
  | ISaveContactDetailsSuccessAction
  | ISaveContactDetailsFailureAction
