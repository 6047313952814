import { Action } from 'redux'

export enum ACTION_TYPE {
  ADDRESS_TRANSLATE = 'ADDRESS_TRANSLATE',
  ADDRESS_TRANSLATE_SUCCESS = 'ADDRESS_TRANSLATE_SUCCESS',
  ADDRESS_TRANSLATE_FAILURE = 'ADDRESS_TRANSLATE_FAILURE',
  ADDRESS_TRANSLATE_RESET = 'ADDRESS_TRANSLATE_RESET',
}

export type AddressTranslate = Action<ACTION_TYPE.ADDRESS_TRANSLATE>
export type AddressTranslateSuccess = Action<ACTION_TYPE.ADDRESS_TRANSLATE_SUCCESS>
export type AddressTranslateFailure = Action<ACTION_TYPE.ADDRESS_TRANSLATE_FAILURE>
export type AddressTranslateReset = Action<ACTION_TYPE.ADDRESS_TRANSLATE_RESET>

export type IActionType =
  | AddressTranslate
  | AddressTranslateSuccess
  | AddressTranslateFailure
  | AddressTranslateReset
