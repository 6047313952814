import { ACTION_TYPE, IActionType } from 'actions/address/types'

export interface IAddressState {
  translating: boolean
  error: boolean
}

const initialState: IAddressState = {
  translating: false,
  error: false,
}

export const addressReducer = (state = initialState, action: IActionType): IAddressState => {
  switch (action.type) {
    case ACTION_TYPE.ADDRESS_TRANSLATE:
      return {
        ...state,
        translating: true,
        error: false,
      }
    case ACTION_TYPE.ADDRESS_TRANSLATE_SUCCESS:
    case ACTION_TYPE.ADDRESS_TRANSLATE_RESET:
      return {
        ...state,
        translating: false,
        error: false,
      }
    case ACTION_TYPE.ADDRESS_TRANSLATE_FAILURE:
      return {
        ...state,
        translating: false,
        error: true,
      }
    default:
      return state
  }
}
