import clientCookies from 'js-cookie'
import ServerCookies from 'cookies'
import { IncomingMessage, ServerResponse } from 'http'
import { addDays } from 'date-fns'

export enum SameSite {
  Strict = 'strict',
  Lax = 'lax',
  None = 'none',
}

export type IsomorphicCookieOptions = {
  expires?: number // number of days in the future
  domain: string // the domain of the cookie
  sameSite: SameSite // indicates whether the cookie is a "same site" cookie
}

export interface CookieProvider {
  get(name: string): string | undefined
  set(name: string, value: string, options: IsomorphicCookieOptions): void
  remove(name: string, domain: string): void
}

export class IsomorphicCookieProvider implements CookieProvider {
  #_req: IncomingMessage | undefined
  #_res: ServerResponse | undefined

  constructor(req?: IncomingMessage, res?: ServerResponse) {
    this.#_req = req
    this.#_res = res
  }

  get(name: string): string | undefined {
    if (this.#_req) {
      const serverCookie = new ServerCookies(this.#_req, undefined as unknown as ServerResponse)

      return serverCookie.get(name)
    }

    return clientCookies.get(name)
  }

  set(name: string, value: string, options: IsomorphicCookieOptions): void {
    if (this.#_req && this.#_res) {
      const serverCookie = new ServerCookies(this.#_req, this.#_res)

      const mappedOptions: ServerCookies.SetOption = {
        domain: options.domain,
        httpOnly: false,
        sameSite: options.sameSite,
      }

      if (options.expires) {
        const now = new Date()
        const utcTimestamp = Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds()
        )
        mappedOptions.expires = addDays(utcTimestamp, options.expires)
      }

      serverCookie.set(name, value, mappedOptions)
    }

    clientCookies.set(name, value, options)
  }

  remove(name: string, domain: string): void {
    if (this.#_req && this.#_res) {
      const serverCookie = new ServerCookies(this.#_req, this.#_res)

      serverCookie.set(name, '', { domain })
    }

    clientCookies.remove(name, {
      domain,
    })
  }
}

export default new IsomorphicCookieProvider()
