import * as Sentry from '@sentry/node'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { resolveSentrySeverity } from 'utils/sentrySeverity'
import { resolveSentryMessage } from 'utils/sentryMessage'
import SentryTransaction from 'utils/sentry-transaction'
import { getRegion } from '../utils/getRegion'

const getAxiosInstance = () => {
  const axiosInstance = axios.create()

  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  axiosInstance.defaults.headers.common['Accept'] = 'application/json'

  return axiosInstance
}

const sitecoreClient = getAxiosInstance()

interface SitecoreApiClient {
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
    transactionName?: string,
    service?: string
  ): Promise<R>
}

export const sitecoreApiClient: SitecoreApiClient = {
  get: <T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
    transactionName?: string,
    service = 'Sitecore'
  ): Promise<R> => {
    const endTransaction = SentryTransaction.beginTransaction(transactionName, {
      data: {
        url,
        service,
      },
    })

    return sitecoreClient
      .get<T, R>(url, config)
      .catch((error) => {
        Sentry.captureEvent({
          message: resolveSentryMessage(service, error.response?.status),
          level: resolveSentrySeverity(error.response?.status),
          tags: {
            service,
            endpoint: url.split('?')[0],
            whichend: process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front',
            region: getRegion(),
          },
        })
        endTransaction(error.response?.status)
        throw error
      })
      .finally(endTransaction)
  },
}
