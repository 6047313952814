import { env } from 'next-runtime-env'
import { createIntegrationConfig } from './environments/integration'
import { createDevelopmentConfig } from './environments/local'
import { createProductionConfig } from './environments/production'
import { createStagingConfig } from './environments/staging'
import { createTestConfig } from './environments/test'

enum BUILD_CONFIGURATION {
  PRODUCTION = 'production',
  STAGING = 'staging',
  INTEGRATION = 'integration',
  TEST = 'test',
  DEVELOPMENT = 'development',
}

function getEnvConfig() {
  const isCn = env('NEXT_PUBLIC_REGION_CONFIG') === 'CN'
  switch (env('NEXT_PUBLIC_CHRISTIES_ENVIRONMENT')) {
    case BUILD_CONFIGURATION.PRODUCTION:
      return createProductionConfig(isCn)
    case BUILD_CONFIGURATION.STAGING:
      return createStagingConfig(isCn)
    case BUILD_CONFIGURATION.INTEGRATION:
      return createIntegrationConfig(isCn)
    case BUILD_CONFIGURATION.TEST:
      return createTestConfig(isCn)
    case BUILD_CONFIGURATION.DEVELOPMENT:
      return createDevelopmentConfig(isCn)
    default:
      // Fail safe to prod in case env variable not provided. CORS errors when running in non-prod environments
      return createProductionConfig(isCn)
  }
}

const environment = getEnvConfig()

export default environment

export const isProductionBuild = (): boolean =>
  process.env.NODE_ENV === BUILD_CONFIGURATION.PRODUCTION
