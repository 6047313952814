import { KYC_STATUS, ROUTE } from 'configuration'
import { useRouter } from 'next/router'
import { useStore } from 'react-redux'
import { IAppState } from 'reducers'

export const getNextKYCUrl = (
  accountNumber: string | undefined,
  kycStatus: KYC_STATUS | null | undefined,
  securityQuestionUpgradeRequired: boolean | undefined,
  securityQuestionOverride = false
): string => {
  if (!accountNumber) {
    return ROUTE.KYC_PERSONAL_DETAILS
  }

  if (kycStatus !== KYC_STATUS.COMPLETE) {
    return ROUTE.ID_VERIFICATION
  }

  if (!securityQuestionOverride) {
    if (securityQuestionUpgradeRequired) {
      return ROUTE.SECURITY_QUESTIONS
    }
  }

  return ROUTE.COMPLETE
}

const useKYCNext = (): ((securityQuestionOverride?: boolean) => void) => {
  const router = useRouter()

  const store = useStore()

  return (securityQuestionOverride = false) => {
    const state = store.getState() as IAppState

    const { accountNumber, kycStatus, securityQuestionUpgradeRequired } = state.account
    router.push(
      getNextKYCUrl(
        accountNumber,
        kycStatus,
        securityQuestionUpgradeRequired,
        securityQuestionOverride
      )
    )
  }
}

export default useKYCNext
