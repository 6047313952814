import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import actions from 'actions/mobileApp'
import { MESSAGE_TYPE, ROUTE } from 'configuration'
import { useRouter } from 'next/router'
import SessionProvider from 'session'

const usePostMessage = (
  isMobileApp: boolean
): {
  callPostMessage: (messageType: MESSAGE_TYPE, navigateTo: string) => void
} => {
  const [pageTitle, setPageTitle] = useState('')

  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    setPageTitle(router.pathname)
  }, [router.pathname])

  const callPostMessage = (messageType: MESSAGE_TYPE, navigateTo: string) => {
    if (isMobileApp) {
      const { accessToken, refreshToken } = SessionProvider.getUserSession()
      const data: {
        type: MESSAGE_TYPE
        payload: {
          pageTitle: string
          navigateTo: string
          access_token: string | null
          refresh_token: string | null
        }
      } = {
        type: messageType,
        payload: {
          pageTitle,
          navigateTo,
          access_token: null,
          refresh_token: null,
        },
      }

      if (accessToken) {
        data.payload.access_token = accessToken
      }

      if (refreshToken) {
        data.payload.refresh_token = refreshToken
      }

      dispatch(actions.postMessageAction(data))
    }
  }

  return { callPostMessage }
}

export const postMessageSession = (
  access_token: string | undefined | null,
  refresh_token: string | undefined | null,
  path?: string
): {
  type: MESSAGE_TYPE
  payload: {
    pageTitle: string
    navigateTo: null
    access_token: string | undefined | null
    refresh_token: string | undefined | null
  }
} => {
  const data = {
    type: MESSAGE_TYPE.SESSION,
    payload: {
      pageTitle: path || ROUTE.SIGN_UP,
      navigateTo: null,
      access_token,
      refresh_token,
    },
  }
  return data
}

export default usePostMessage
