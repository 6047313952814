import { AxiosResponse } from 'axios'
import { KYC_DOCUMENT_STATUS, KYC_STATUS } from 'configuration'
import environment from 'configuration/env'
import { mulesoftApiClient } from 'infrastructure/mulesoft-client'
import { TokenType } from 'infrastructure/token'
import SessionProvider from 'session'
import { getClientIdFromToken } from 'utils/token-decoder'

const createAccountServiceUrl = `${environment.apimUrl}/clientlogin/legacy/create-prospect`
const emailAddressExistsServiceUrl = `${environment.apimUrl}/clientlogin/legacy/email-address-exists-body`
const getAccountServiceUrl = `${environment.apimUrl}/clientlogin/legacy/client-details`
const updateCommunicationPreferencesServiceUrl = `${environment.apimUrl}/clientlogin/legacy/communication-preferences`
const getAutoAccountServiceUrl = (ac: string) =>
  `${environment.apimUrl}/clientlogin/legacy/auto-account-user-detail?validation_code=${ac}`
const activateAccountServiceUrl = `${environment.apimUrl}/clientlogin/legacy/activate-account`

type CreateAccountApiRequest = {
  username: string
  password: string
  title: string
  firstname: string
  lastname: string
  geocountrycode: string
  account_number?: string
  language_id: number
}

export type CreateAccountApiResponse = {
  access_token: string
  refresh_token: string
  scope: string
  expires_in: number
  token_type: string
}

export type CreateAccountDetails = {
  userName: string
  password: string
  title: string
  firstName: string
  lastName: string
  geoCountryCode: string
  accountNumber?: string
  language_id: number
}

export enum EMAIL_VALIDATED {
  Y = 'Y',
  N = 'N',
}

export type AccountApiResponse = {
  ClientGuid: string
  Type: string
  Attributes: [
    {
      AccountNumber: string
      ClientTitle: string
      ClientFirstName: string
      ClientLastName: string
      EmailAddress: string
      IsOver18: string
      DisplayMyFico: string
      ValidationInformation: [
        {
          PasswordUpgradeRequired: string
          SecurityQuestionUpgradeRequired: string
          FicoUpgradeRequired: string
          MissingAMLDetails: string
          EmailAddressValidated: EMAIL_VALIDATED
          WebViewURL: string
          Message: string
          KYC_IDStatus: KYC_DOCUMENT_STATUS | null
          KYC_AddressStatus: KYC_DOCUMENT_STATUS | null
          KYC_Status: KYC_STATUS | null
        },
      ]
      AccountsDropdown: [
        {
          AccountDisplay: string
          AccountNumber: string
          AccountType: string
        },
      ]
    },
  ]
}

export type AutoAccountApiResponse = {
  client_guid: string
  email_address: string
}

export type UpdatePasswordApiRequest = {
  password: string
}

export type UpdatePasswordApiResponse = {
  access_token: string
  refresh_token: string
  scope: string
  expires_in: number
  token_type: string
}

export type CommunicationPreferences = {
  optinEmailAuctionUpdates: boolean
  optinEmailArtNews: boolean
  optinEmailEvents: boolean
  optinEmailConsignmentValuations: boolean
  optinEmailLotAlerts: boolean
  optinEmailEducation: boolean
  optinEmailRealEstate: boolean
}

type UpdateCommunicationPreferencesApiRequest = {
  optin_email_auction_updates: string
  optin_email_art_news: string
  optin_email_events: string
  optin_email_consignment_valuations: string
  optin_email_lot_alerts: string
  optin_email_education: string
  optin_email_real_estate: string
}

export type EmailExistsApiResponse = {
  email_address_exists: boolean
}

class AccountService {
  createAccount(account: CreateAccountDetails): Promise<CreateAccountApiResponse> {
    const payload: CreateAccountApiRequest = {
      username: account.userName,
      password: account.password,
      title: account.title,
      firstname: account.firstName,
      lastname: account.lastName,
      geocountrycode: account.geoCountryCode,
      account_number: account.accountNumber,
      language_id: account.language_id,
    }

    return mulesoftApiClient
      .post<
        CreateAccountApiRequest,
        AxiosResponse<CreateAccountApiResponse>
      >(createAccountServiceUrl, payload, { tokenType: TokenType.Application }, 'POST /clientLogin/create-prospect')
      .then((response) => {
        const { access_token: AccessToken, refresh_token: RefreshToken } = response.data

        SessionProvider.setUserSession({
          accessToken: AccessToken,
          refreshToken: RefreshToken,
          clientId: getClientIdFromToken(AccessToken),
        })

        return response.data
      })
  }

  emailExists(emailAddress: string, hCaptchaToken: string): Promise<EmailExistsApiResponse> {
    return mulesoftApiClient
      .post<any, AxiosResponse<EmailExistsApiResponse>>(
        emailAddressExistsServiceUrl,
        {
          email_address: emailAddress,
          h_captcha_response_token: hCaptchaToken,
        },
        { tokenType: TokenType.Application },
        'POST /clientLogin/email-address-exists-body'
      )
      .then((response) => response.data)
  }

  getAccount(): Promise<AccountApiResponse> {
    return mulesoftApiClient
      .get<unknown, AxiosResponse<AccountApiResponse>>(
        getAccountServiceUrl,
        {
          tokenType: TokenType.User,
        },
        'POST /clientLogin/client-details'
      )
      .then((response) => {
        return response.data
      })
  }

  getAccountByAutoAccountToken(ac: string): Promise<AutoAccountApiResponse> {
    return mulesoftApiClient
      .get<unknown, AxiosResponse<AutoAccountApiResponse>>(
        getAutoAccountServiceUrl(ac),
        {
          tokenType: TokenType.Application,
        },
        'POST /clientLogin/auto-account-user-detail'
      )
      .then((response) => {
        return response.data
      })
  }

  activateAccount(ac: string, password: string): Promise<UpdatePasswordApiResponse> {
    return mulesoftApiClient
      .post<any, AxiosResponse<UpdatePasswordApiResponse>>(
        activateAccountServiceUrl,
        {
          Password: password,
        },
        {
          tokenType: TokenType.Application,
          headers: {
            validation_code: ac,
          },
        },
        'POST /clientLogin/activate-account'
      )
      .then((response) => {
        const { access_token: AccessToken, refresh_token: RefreshToken } = response.data

        SessionProvider.setUserSession({
          accessToken: AccessToken,
          refreshToken: RefreshToken,
          clientId: getClientIdFromToken(AccessToken),
        })

        return response.data
      })
  }

  updateCommunicationPreferences(
    communicationPreferences: CommunicationPreferences
  ): Promise<void> {
    const boolToOptInFlag = (value: boolean) => (value ? 'I' : 'O')

    const payload: UpdateCommunicationPreferencesApiRequest = {
      optin_email_art_news: boolToOptInFlag(communicationPreferences.optinEmailArtNews),
      optin_email_auction_updates: boolToOptInFlag(
        communicationPreferences.optinEmailAuctionUpdates
      ),
      optin_email_consignment_valuations: boolToOptInFlag(
        communicationPreferences.optinEmailConsignmentValuations
      ),
      optin_email_events: boolToOptInFlag(communicationPreferences.optinEmailEvents),
      optin_email_lot_alerts: boolToOptInFlag(communicationPreferences.optinEmailLotAlerts),
      optin_email_education: boolToOptInFlag(communicationPreferences.optinEmailEducation),
      optin_email_real_estate: boolToOptInFlag(communicationPreferences.optinEmailRealEstate),
    }
    return mulesoftApiClient
      .post<UpdateCommunicationPreferencesApiRequest, AxiosResponse<any>>(
        updateCommunicationPreferencesServiceUrl,
        payload,
        {
          tokenType: TokenType.User,
        },
        'POST /clientLogin/communication-preferences'
      )
      .then(() => undefined)
  }
}

export default new AccountService()
