import { MESSAGE_TYPE } from 'configuration/mobileApp'
import { ActionCreator } from 'redux'
import { ACTION_TYPE, PostMessage, SetMobileFlag } from './types'

export const postMessageAction: ActionCreator<PostMessage> = (payload: {
  type: MESSAGE_TYPE
  payload: {
    pageTitle: string
    navigateTo: string
    access_token: string | null
    refresh_token: string | null
  }
}): PostMessage => ({
  type: ACTION_TYPE.POST_MESSAGE,
  payload,
})

export const setMobileFlag: ActionCreator<SetMobileFlag> = (): SetMobileFlag => ({
  type: ACTION_TYPE.SET_MOBILE_FLAG,
})

export default {
  postMessageAction,
  setMobileFlag,
}
