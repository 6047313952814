import { AxiosResponse } from 'axios'
import environment from 'configuration/env'
import { mulesoftApiClient } from 'infrastructure/mulesoft-client'
import { TokenType } from 'infrastructure/token'

const getServiceUrl = (zipcode: string) =>
  `${environment.apimUrl}/browse/legacy/us-zipcode-info?zipcode=${zipcode}`

type ZipCodeApiResponse = {
  zipcode_info: Array<{
    city: string
    city_alias_name: string
    is_main_city: boolean
    county: string
    is_main_county: boolean
    state: string
    state_full_name: string
  }>
}

class UsZipcodeLookup {
  get(zipcode: string): Promise<ZipCodeApiResponse> {
    return mulesoftApiClient
      .get<unknown, AxiosResponse<ZipCodeApiResponse>>(
        getServiceUrl(zipcode),
        {
          tokenType: TokenType.Application,
        },
        'GET /browse/us-zipcode-info'
      )
      .then((response) => response.data)
  }
}

export default new UsZipcodeLookup()
