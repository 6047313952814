import { Action, ActionCreator } from 'redux'
import { AccountApiResponse } from 'services/account'

export enum ACTION_TYPE {
  GET_ACCOUNT_DETAILS = 'CHR/ACCOUNT/GET_ACCOUNT_DETAILS',
  GET_ACCOUNT_DETAILS_SUCCESS = 'CHR/ACCOUNT/GET_ACCOUNT_DETAILS/SUCCESS',
  GET_ACCOUNT_DETAILS_FAILURE = 'CHR/ACCOUNT/GET_ACCOUNT_DETAILS/FAILURE',
  SET_ACCOUNT_DETAILS_STALE = 'CHR/ACCOUNT/SET_ACCOUNT_DETAILS/STALE',
  AUTO_ACCOUNT_LOADED = 'CHR/ACCOUNT/AUTO_ACCOUNT_LOADED',
  SET_KYC_ID_UPLOAD_STATUS_SUCCESS = 'SET_KYC_ID_UPLOAD_STATUS_SUCCESS',
  SET_KYC_ID_UPLOAD_STATUS_HALF_SUCCESS = 'SET_KYC_ID_UPLOAD_STATUS_HALF_SUCCESS',
  SET_KYC_ID_UPLOAD_STATUS_FAIL = 'SET_KYC_ID_UPLOAD_STATUS_FAIL',
  SET_KYC_ADDRESS_UPLOAD_STATUS_SUCCESS = 'SET_KYC_ADDRESS_UPLOAD_STATUS_SUCCESS',
  SET_KYC_ADDRESS_UPLOAD_STATUS_FAIL = 'SET_KYC_ADDRESS_UPLOAD_STATUS_FAIL',
}

export type AccountPayload = {
  payload?: AccountApiResponse & { error?: string }
}

export type AccountAction = Action<ACTION_TYPE> & AccountPayload

export const createAccountAction: ActionCreator<AccountAction> = (
  type: ACTION_TYPE = ACTION_TYPE.GET_ACCOUNT_DETAILS,
  payload?: AccountApiResponse
): AccountAction => ({
  type,
  payload,
})

export const setKycIdUploadStatus: ActionCreator<AccountAction> = (
  type: ACTION_TYPE = ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_SUCCESS
) => ({
  type,
})

export const setKycAddressUploadStatus: ActionCreator<AccountAction> = (
  type: ACTION_TYPE = ACTION_TYPE.SET_KYC_ADDRESS_UPLOAD_STATUS_SUCCESS
) => ({
  type,
})

export default {
  createAccountAction,
  setKycIdUploadStatus,
  setKycAddressUploadStatus,
}
