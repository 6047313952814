import { env } from 'next-runtime-env'
import { Environment, KeysWithFallbackValue, RequiredEnvConfig } from './types'

const defaultConfig: Pick<Environment, KeysWithFallbackValue> = {
  hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
  dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
  buildId: process.env.CONFIG_BUILD_ID as string,
  debug: env('NEXT_PUBLIC_DEBUG') ?? 'false',
  name: `${env('NEXT_PUBLIC_CHRISTIES_ENVIRONMENT')}_${env('NEXT_PUBLIC_REGION_CONFIG')}`,
  auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
  auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
  tracesSampleRate: 1,
}

export function defineEnvConfig(config: RequiredEnvConfig): Environment {
  // Don't emit this line if we're running under unit test conditions
  if (process.env.JEST_WORKER_ID === undefined) {
    // eslint-disable-next-line no-console
    console.info(
      `Operating in ${config.name ?? defaultConfig.name}, environment variable was set to ${env(
        'NEXT_PUBLIC_CHRISTIES_ENVIRONMENT'
      )}, the build configuration is ${process.env.NODE_ENV} and the build ID is ${
        process.env.CONFIG_BUILD_ID
      }`
    )
  }

  return {
    ...defaultConfig,
    ...config,
  }
}
