import { AccountAction, ACTION_TYPE as ACCOUNT_ACTION_TYPE } from 'actions/account'
import {
  ACTION_TYPE as PREFS_ACTION_TYPE,
  IActionType as IPrefsActionType,
} from 'actions/accountCreation/types'
import { EMAIL_VALIDATED } from 'services/account'
import { KYC_DOCUMENT_STATUS, KYC_STATUS } from 'configuration'

export interface IAccountState {
  loading: boolean
  error?: string
  type?: string
  clientGuid?: string
  accountNumber?: string
  phoneNumber?: string
  title?: string
  firstName?: string
  lastName?: string
  email?: string
  emailValidated?: EMAIL_VALIDATED
  kycStatus?: KYC_STATUS | null
  kycIdStatus?: KYC_DOCUMENT_STATUS | null
  kycIdAdditionalVerificationRequired?: boolean
  kycAddressStatus?: KYC_DOCUMENT_STATUS | null
  securityQuestionUpgradeRequired?: boolean
  hasAccountDetails: boolean
}

const initialState: IAccountState = {
  loading: false,
  error: undefined,
  type: undefined,
  clientGuid: undefined,
  accountNumber: undefined,
  title: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  kycStatus: undefined,
  emailValidated: undefined,
  kycIdStatus: undefined,
  kycIdAdditionalVerificationRequired: false,
  kycAddressStatus: undefined,
  phoneNumber: undefined,
  hasAccountDetails: false,
}

export const accountReducer = (
  state: IAccountState = initialState,
  action: IPrefsActionType | AccountAction
): IAccountState => {
  switch (action.type) {
    case PREFS_ACTION_TYPE.UPDATE_PREFERENCES:
    case ACCOUNT_ACTION_TYPE.GET_ACCOUNT_DETAILS:
    case PREFS_ACTION_TYPE.SAVE_CONTACT_DETAILS: {
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    }
    case PREFS_ACTION_TYPE.UPDATE_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      }
    case PREFS_ACTION_TYPE.UPDATE_PREFERENCES_FAILURE:
    case PREFS_ACTION_TYPE.SAVE_CONTACT_DETAILS_FAILURE:
    case ACCOUNT_ACTION_TYPE.GET_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
      }

    case ACCOUNT_ACTION_TYPE.GET_ACCOUNT_DETAILS_SUCCESS: {
      const attributes = action.payload?.Attributes[0]
      const validationInformation = action.payload?.Attributes[0].ValidationInformation[0]
      return {
        ...state,
        loading: false,
        error: undefined,
        type: action.payload?.Type,
        clientGuid: action.payload?.ClientGuid,
        accountNumber: attributes?.AccountNumber,
        title: attributes?.ClientTitle,
        firstName: attributes?.ClientFirstName,
        lastName: attributes?.ClientLastName,
        email: attributes?.EmailAddress,
        emailValidated: validationInformation?.EmailAddressValidated,
        kycIdStatus: validationInformation?.KYC_IDStatus,
        kycAddressStatus: validationInformation?.KYC_AddressStatus,
        kycStatus: validationInformation?.KYC_Status,
        securityQuestionUpgradeRequired:
          validationInformation?.SecurityQuestionUpgradeRequired === 'Y',
        hasAccountDetails: true,
      }
    }

    case ACCOUNT_ACTION_TYPE.SET_ACCOUNT_DETAILS_STALE: {
      return {
        ...state,
        hasAccountDetails: false,
      }
    }

    case PREFS_ACTION_TYPE.SAVE_CONTACT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        kycStatus: action.payload.kyc_status as KYC_STATUS,
        accountNumber: action.payload.account_number,
      }
    }

    case ACCOUNT_ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        kycIdStatus: KYC_DOCUMENT_STATUS.PASS,
        kycIdAdditionalVerificationRequired: false,
      }
    }

    case ACCOUNT_ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_HALF_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        kycIdStatus: KYC_DOCUMENT_STATUS.PASS,
        kycIdAdditionalVerificationRequired: true,
      }
    }

    case ACCOUNT_ACTION_TYPE.SET_KYC_ID_UPLOAD_STATUS_FAIL: {
      return {
        ...state,
        loading: false,
        error: undefined,
        kycIdStatus: KYC_DOCUMENT_STATUS.FAIL,
      }
    }

    case ACCOUNT_ACTION_TYPE.SET_KYC_ADDRESS_UPLOAD_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        kycStatus: KYC_STATUS.COMPLETE,
        kycAddressStatus: KYC_DOCUMENT_STATUS.PASS,
      }
    }

    case ACCOUNT_ACTION_TYPE.SET_KYC_ADDRESS_UPLOAD_STATUS_FAIL: {
      return {
        ...state,
        loading: false,
        error: undefined,
        kycAddressStatus: KYC_DOCUMENT_STATUS.FAIL,
      }
    }

    default:
      return state
  }
}
