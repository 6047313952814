export enum DOCUMENT_TYPE {
  KYC = 'kyc',
  POF = 'pof',
}

export enum KYC_STATUS {
  REQUIRED = 'Required',
  COMPLETE = 'Completed',
  AWAITING_PHOTO_ID = 'Awaiting Photo ID',
}

export enum KYC_DOCUMENT_STATUS {
  PASS = 'Pass',
  FAIL = 'Fail',
}

export const KYC_UPLOAD_ATTEMPT_CONFIG = {
  MAX_ATTEMPTS: 3,
  ATTEMPT_PERIOD_HOURS: 24,
  KYC_UPLOAD_DOCUMENT_ATTEMPTS_KEY: 'kyc-upload-document-attempts',
}
