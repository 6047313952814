import {
  ILoginAction,
  ILoginSuccessAction,
  ILoginFailureAction,
  ICheckEmailAction,
  ICheckEmailSuccessAction,
  ICheckEmailFailureAction,
  IChangeStepAction,
  IResetEmailAction,
  IRegisterUserAction,
  IRegisterUserFailureAction,
  IRegisterUserSuccessAction,
  ACTION_TYPE,
  IActivateAccountAction,
  ISetLanguageAction,
} from 'actions/authentication/types'
import { ILoginUser, ICheckUserEmail, ICreateUser } from 'models/user'

import { IErrorMessage } from 'models/error'

const userAuthenticateAction = (data: ILoginUser): ILoginAction => ({
  type: ACTION_TYPE.USER_LOGIN,
  payload: data,
})

const userAuthenticateSuccessAction = (): ILoginSuccessAction => ({
  type: ACTION_TYPE.USER_LOGIN_SUCCESS,
})

const userAuthenticateFailureAction = (error: string): ILoginFailureAction => ({
  type: ACTION_TYPE.USER_LOGIN_FAILURE,
  payload: { error },
})

const checkEmailAction = (data: ICheckUserEmail): ICheckEmailAction => ({
  type: ACTION_TYPE.CHECK_EMAIL,
  payload: data,
})

const checkEmailSuccessAction = (): ICheckEmailSuccessAction => ({
  type: ACTION_TYPE.CHECK_EMAIL_SUCCESS,
})

const checkEmailFailureAction = (error: string): ICheckEmailFailureAction => ({
  type: ACTION_TYPE.CHECK_EMAIL_FAILURE,
  payload: { error },
})

const changeStepAction = (step: number): IChangeStepAction => ({
  type: ACTION_TYPE.CHANGE_STEP,
  payload: { step },
})

const setLanguageAction = (language: string): ISetLanguageAction => ({
  type: ACTION_TYPE.SET_LANGUAGE,
  payload: { language },
})

const resetErrorAction = (): IResetEmailAction => ({
  type: ACTION_TYPE.RESET_ERROR,
})

const userRegisterAction = (
  data: ICreateUser & IErrorMessage & { isMobileApp: boolean }
): IRegisterUserAction => ({ type: ACTION_TYPE.USER_REGISTER, payload: data })

const userRegisterSuccessAction = (): IRegisterUserSuccessAction => ({
  type: ACTION_TYPE.USER_REGISTER_SUCCESS,
})

const userRegisterFailureAction = (error: string): IRegisterUserFailureAction => ({
  type: ACTION_TYPE.USER_REGISTER_FAILURE,
  payload: { error },
})

const activateAccountAction = (
  ac: string,
  password: string,
  isMobileApp: boolean
): IActivateAccountAction => ({
  type: ACTION_TYPE.ACTIVATE_ACCOUNT,
  payload: { ac, password, isMobileApp },
})

export default {
  userAuthenticateAction,
  userAuthenticateSuccessAction,
  userAuthenticateFailureAction,
  checkEmailAction,
  checkEmailSuccessAction,
  checkEmailFailureAction,
  changeStepAction,
  setLanguageAction,
  resetErrorAction,
  userRegisterAction,
  userRegisterSuccessAction,
  userRegisterFailureAction,
  activateAccountAction,
}
