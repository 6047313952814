import {
  ACTION_TYPE,
  KycDocumentUpload,
  KycDocumentUploadSuccess,
  KycDocumentUploadFailure,
} from 'actions/kyc/types'
import { ActionCreator } from 'redux'
import { DOCUMENT_TYPE } from 'configuration'

export const KycDocumentUploadAction: ActionCreator<KycDocumentUpload> = (
  file: File,
  documentType: DOCUMENT_TYPE
): KycDocumentUpload => ({
  type: ACTION_TYPE.KYC_DOCUMENT_UPLOAD,
  payload: {
    file,
    documentType,
  },
})

export const KycDocumentUploadSuccessAction: ActionCreator<
  KycDocumentUploadSuccess
> = (): KycDocumentUploadSuccess => ({
  type: ACTION_TYPE.KYC_DOCUMENT_UPLOAD_SUCCESS,
})

export const KycDocumentUploadFailureAction: ActionCreator<
  KycDocumentUploadFailure
> = (): KycDocumentUploadFailure => ({
  type: ACTION_TYPE.KYC_DOCUMENT_UPLOAD_FAILURE,
})

export default {
  KycDocumentUploadAction,
  KycDocumentUploadSuccessAction,
  KycDocumentUploadFailureAction,
}
