import * as Sentry from '@sentry/node'
import { ACTION_TYPE, PostMessage } from 'actions/mobileApp/types'
import { call, CallEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'

export const sagas = [takeEvery(ACTION_TYPE.POST_MESSAGE, postmobileMessage)]

function* postmobileMessage(action: PostMessage): Generator<SelectEffect | CallEffect<unknown>> {
  try {
    Sentry.addBreadcrumb({
      category: 'Account Creation',
      message: `Attempting to push mobile event { messageType: ${action.payload.type}, navigateTo: ${action.payload.payload.navigateTo}, pageTitle: ${action.payload.payload.pageTitle} }`,
      level: Sentry.Severity.Info,
    })

    const isMobileApp = yield select((state): boolean => state.authentication.isMobile)
    if (isMobileApp) {
      // @ts-ignore
      yield call(
        // @ts-ignore
        [window.webkit.messageHandlers.nativeProcess, 'postMessage'],
        JSON.stringify(action.payload)
      )
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}
