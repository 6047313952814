import { ACTION_TYPE, createAccountAction } from 'actions/account'
import {
  createSaveContactDetailsFailureAction,
  createSaveContactDetailsSuccessAction,
} from 'actions/accountCreation'
import {
  ACTION_TYPE as ACCOUNT_ACTION_TYPE,
  ISaveContactDetailsAction,
} from 'actions/accountCreation/types'
import { KYC_STATUS } from 'configuration'
import { getNextKYCUrl } from 'hooks/useKYCNext'
import router from 'next/router'
import { IAppState } from 'reducers'
import { Action } from 'redux'
import { call, CallEffect, put, PutEffect, select, takeEvery } from 'redux-saga/effects'
import ContactDetailsService, { ContactDetailsApiResponse } from 'services/contact-details'

export const sagas = [
  takeEvery(ACCOUNT_ACTION_TYPE.SAVE_CONTACT_DETAILS, saveContactDetails),
  takeEvery(ACCOUNT_ACTION_TYPE.SAVE_CONTACT_DETAILS_SUCCESS, contactDetailsSaved),
]

export function* saveContactDetails(
  action: ISaveContactDetailsAction
): Generator<
  | CallEffect<ContactDetailsApiResponse>
  | unknown
  | PutEffect<Action<ACCOUNT_ACTION_TYPE.SAVE_CONTACT_DETAILS_FAILURE>>
  | PutEffect<Action<ACCOUNT_ACTION_TYPE.SAVE_CONTACT_DETAILS_SUCCESS>>
> {
  const { errorMessage, ...payload } = action.payload
  try {
    const result: ContactDetailsApiResponse = (yield call(
      ContactDetailsService.save,
      payload
    ) as unknown) as ContactDetailsApiResponse

    yield put(createSaveContactDetailsSuccessAction(result))
  } catch (e) {
    yield put(createSaveContactDetailsFailureAction(errorMessage))
  }
}

type AccountState = {
  accountNumber: string | undefined
  kycStatus: KYC_STATUS | null | undefined
  securityQuestionUpgradeRequired: boolean | undefined
}
const getAccountState = (state: IAppState): AccountState => ({
  accountNumber: state.account.accountNumber,
  kycStatus: state.account.kycStatus,
  securityQuestionUpgradeRequired: state.account.securityQuestionUpgradeRequired,
})

export function* contactDetailsSaved(): Generator<any> {
  yield put(createAccountAction(ACTION_TYPE.SET_ACCOUNT_DETAILS_STALE))

  const { accountNumber, kycStatus, securityQuestionUpgradeRequired } = (yield select(
    getAccountState
  ) as unknown) as AccountState

  const nextUrl = getNextKYCUrl(accountNumber, kycStatus, securityQuestionUpgradeRequired)

  yield call(router.push, nextUrl)
}
