import { MESSAGE_TYPE } from 'configuration/mobileApp'
import { Action } from 'redux'

export enum ACTION_TYPE {
  POST_MESSAGE = 'POST_MESSAGE',
  SET_MOBILE_FLAG = 'SET_MOBILE_FLAG',
}

type PostMessagePayload = {
  payload: {
    type: MESSAGE_TYPE
    payload: {
      pageTitle: string
      navigateTo: string
      access_token: string | null
      refresh_token: string | null
    }
  }
}

export type SetMobileFlag = Action<ACTION_TYPE.SET_MOBILE_FLAG>
export type PostMessage = Action<ACTION_TYPE.POST_MESSAGE> & PostMessagePayload

export type IActionType = PostMessage | SetMobileFlag
