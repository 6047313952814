import { ACTION_TYPE, IActionType } from 'actions/authentication/types'
import {
  ACTION_TYPE as MOBILE_ACTIONS,
  IActionType as IMobileActionTypes,
} from 'actions/mobileApp/types'
import { LANGUAGE_CONFIG } from 'configuration/translations'
export interface IAuthenticationState {
  loading: boolean
  error?: string
  step: number
  isMobile: boolean
  language: string
}

const initialState = {
  loading: false,
  error: undefined,
  step: 1,
  isMobile: false,
  language: LANGUAGE_CONFIG.default,
}

export const authenticationReducer = (
  state: IAuthenticationState = initialState,
  action: IActionType | IMobileActionTypes
): IAuthenticationState => {
  switch (action.type) {
    case ACTION_TYPE.USER_LOGIN:
    case ACTION_TYPE.USER_REGISTER:
    case ACTION_TYPE.CHECK_EMAIL:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case ACTION_TYPE.USER_LOGIN_SUCCESS:
    case ACTION_TYPE.USER_REGISTER_SUCCESS:
    case ACTION_TYPE.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      }
    case ACTION_TYPE.USER_LOGIN_FAILURE:
    case ACTION_TYPE.USER_REGISTER_FAILURE:
    case ACTION_TYPE.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case ACTION_TYPE.CHANGE_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case ACTION_TYPE.RESET_ERROR:
      return {
        ...state,
        error: undefined,
      }
    case ACTION_TYPE.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      }
    case MOBILE_ACTIONS.SET_MOBILE_FLAG:
      return {
        ...state,
        isMobile: true,
      }
    default:
      return state
  }
}
