export enum MULESOFT_MESSAGE {
  LOCKED_ACCOUNT = 'Failed login (locked account)',
  INVALID_GRANT = 'Failed login (invalid credentials)',
  API_ERROR = 'Mulesoft API error',
  CLIENT_ERROR = 'Mulesoft bad request from client',
  UNSPECIFIED_ERROR = 'Mulesoft failed request (CORS, etc.)',
}

enum GENERAL_MESSAGE {
  API_ERROR = 'API error',
  CLIENT_ERROR = 'bad request from client',
  UNSPECIFIED_ERROR = 'failed request (CORS, etc.)',
}

/**
 * Resolves a message for Sentry issues dashboard.
 * @param httpStatus
 * @param innerError
 * @param errorDescription
 * @returns a custom error message for Sentry issues dashboard
 */
export const resolveMulesoftSentryMessage = (
  httpStatus?: number,
  innerError?: string,
  errorDescription?: string
): string => {
  if (!httpStatus) {
    return MULESOFT_MESSAGE.UNSPECIFIED_ERROR
  }

  if (httpStatus >= 500) {
    return `${MULESOFT_MESSAGE.API_ERROR} (${httpStatus})`
  }

  if (innerError === 'too_many_attempts' || errorDescription?.includes('Too many attempts')) {
    return MULESOFT_MESSAGE.LOCKED_ACCOUNT
  }

  if (httpStatus === 403 || innerError === 'invalid_grant') {
    return MULESOFT_MESSAGE.INVALID_GRANT
  }

  if (errorDescription) {
    return `Mulesoft ${errorDescription} (${httpStatus})`
  }

  return `${MULESOFT_MESSAGE.CLIENT_ERROR} (${httpStatus})`
}

/**
 * Resolves a message for Sentry issues dashboard.
 * @param service service name
 * @param httpStatus
 * @returns a custom error message for Sentry issues dashboard
 */
export const resolveSentryMessage = (service: string, httpStatus?: number): string => {
  if (!httpStatus) {
    return `${service} ${GENERAL_MESSAGE.UNSPECIFIED_ERROR}`
  }

  if (httpStatus >= 500) {
    return `${service} ${GENERAL_MESSAGE.API_ERROR} (${httpStatus})`
  }

  return `${service} ${GENERAL_MESSAGE.CLIENT_ERROR} (${httpStatus})`
}
