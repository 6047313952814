import { createReduxEnhancer } from '@sentry/react'
import { isProductionBuild } from 'configuration/env'
import { createWrapper, MakeStore } from 'next-redux-wrapper'
import rootReducer, { IAppState } from 'reducers'
import { applyMiddleware, compose, createStore, Store, StoreEnhancer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import createSagaMiddleware, { Task } from 'redux-saga'
import 'regenerator-runtime/runtime'
import rootSaga from 'sagas'

const sentryReduxEnhancer = createReduxEnhancer({
  configureScopeWithState: (scope, state: IAppState) => {
    if (state.account.accountNumber) {
      scope.setTag('christies.account.accountNumber', state.account.accountNumber)
    }
  },
})

export interface SagaStore extends Store {
  sagaTask: Task
  runSagaTask: () => void
}

export const makeStore: MakeStore<IAppState> = () => {
  // 1: Create middleware
  let middleware: any
  let enhancer: StoreEnhancer<any>
  const sagaMiddleware = createSagaMiddleware()
  const loggerMiddlware = createLogger()
  if (isProductionBuild()) {
    middleware = [sagaMiddleware]
    enhancer = compose(applyMiddleware(...middleware), sentryReduxEnhancer)
  } else {
    middleware = [sagaMiddleware]

    if (process.env.NEXT_IS_SERVER !== 'true') {
      middleware.push(loggerMiddlware)
    }

    enhancer = composeWithDevTools(applyMiddleware(...middleware))
  }

  // 2: Add an extra parameter for applying middleware:
  // @ts-ignore
  const store = createStore(rootReducer, enhancer)

  // 3: Run your sagas on server
  ;(store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga)

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga)
  }

  // 4: now return the store:
  return store
}

export const wrapper = createWrapper<IAppState>(makeStore, {
  debug: false,
})
